import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  StatusMessage,
} from '@innoit/ui-components';
import React, { Fragment, ReactNode } from 'react';
import { useTranslation } from 'src/i18n';

export interface SubmitAreaProps {
  hidden?: boolean;
  onCancel?: () => void;
  cancelButtonText?: string | ReactNode;
  submitButtonText?: string | ReactNode;
  cancelButtonColor?: ButtonProps['color'];
  submitButtonColor?: ButtonProps['color'];
  errorText?: string | ReactNode;
  disabled?: boolean;
  sx?: BoxProps['sx'];
  children?: ReactNode;
}

export const SubmitArea: React.FC<SubmitAreaProps> = ({
  hidden,
  onCancel,
  cancelButtonText,
  submitButtonText,
  cancelButtonColor = 'secondary',
  submitButtonColor = 'primary',
  disabled = false,
  errorText = null,
  children = null,
  sx,
}) => {
  const { t } = useTranslation();

  return hidden ? (
    <Fragment />
  ) : (
    <Box sx={sx}>
      {children}
      <Box display="flex" justifyContent="space-between" my={3}>
        {onCancel && (
          <Button
            variant="contained"
            onClick={onCancel}
            color={cancelButtonColor}
          >
            {cancelButtonText ?? t('jsf:button.cancel')}
          </Button>
        )}
        <Button
          variant="contained"
          type="submit"
          color={submitButtonColor}
          disabled={disabled}
        >
          {submitButtonText ?? t('jsf:button.submit')}
        </Button>
      </Box>
      {typeof errorText === 'string' ? (
        <StatusMessage error>{errorText}</StatusMessage>
      ) : (
        errorText
      )}
    </Box>
  );
};
