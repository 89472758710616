import {
  SampleTypeDTO,
  SampleTypeStatusEnum,
  useGetSampleTypesByStatus,
} from '@tsp/api-hooks';
import { contextNotInitialized } from '@tsp/shared-utils';
import { createContext, ReactNode, useCallback, useEffect } from 'react';

interface ISampleTypeContext {
  sampleTypes?: Readonly<Array<SampleTypeDTO>>;
  fetchError?: ReturnType<typeof useGetSampleTypesByStatus>['error'];
  fetchStatus: ReturnType<typeof useGetSampleTypesByStatus>['status'];
  refetchSampleTypes: () => void;
}

export const SampleTypeContext = createContext<ISampleTypeContext>({
  refetchSampleTypes: contextNotInitialized,
  fetchStatus: 'idle',
});

export const SampleTypeContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    sampleTypes,
    error: fetchError,
    status: fetchStatus,
    dispatch,
  } = useGetSampleTypesByStatus();

  const fetchSampleTypes = useCallback(() => {
    dispatch([
      SampleTypeStatusEnum.Active,
      SampleTypeStatusEnum.Archived,
      SampleTypeStatusEnum.Draft,
    ]);
  }, [dispatch]);

  useEffect(() => {
    fetchSampleTypes();
  }, [fetchSampleTypes]);

  return (
    <SampleTypeContext.Provider
      value={{
        sampleTypes,
        fetchStatus,
        fetchError,
        refetchSampleTypes: fetchSampleTypes,
      }}
    >
      {children}
    </SampleTypeContext.Provider>
  );
};
