import {
  ArrayDefinition,
  BooleanDefinition,
  DateDefinitionFormat,
  Definition,
  EnumDefinition,
  IntegerDefinition,
  NullDefinition,
  NumberDefinition,
  ObjectDefinition,
  RefDefinition,
  StringDefinition,
} from 'src/types/definition';
import {
  ConditionalDependency,
  ConditionalEnumDependency,
  Dependency,
  RequiringDependency,
} from 'src/types/dependency';
import {
  FormBuilderObjectSchema,
  FormBuilderSchema,
} from 'src/types/json-schema';
import { Property } from 'src/types/property';

export const isArrayDefinition = (
  obj: Definition | Property
): obj is ArrayDefinition => 'type' in obj && obj.type === 'array';

export const isStringDefinition = (
  obj: Definition | Property
): obj is StringDefinition => 'type' in obj && obj.type === 'string';

export const isEnumDefinition = (
  obj: Definition | Property
): obj is EnumDefinition => isStringDefinition(obj) && 'enum' in obj;

export const isDateDefinition = (
  obj: Definition | Property
): obj is StringDefinition =>
  isStringDefinition(obj) &&
  Object.values<string>(DateDefinitionFormat).includes(`${obj.format}`);

export const isBooleanDefinition = (
  obj: Definition | Property
): obj is BooleanDefinition => 'type' in obj && obj.type === 'boolean';

export const isNumberDefinition = (
  obj: Definition | Property
): obj is NumberDefinition => 'type' in obj && obj.type === 'number';

export const isIntegerDefinition = (
  obj: Definition
): obj is IntegerDefinition => 'type' in obj && obj.type === 'integer';

export const isNullDefinition = (
  obj: Definition | Property
): obj is NullDefinition => 'type' in obj && obj.type === 'null';

export const isRefDefinition = (
  obj: Definition | Property
): obj is RefDefinition => '$ref' in obj;

export const isObjectDefinition = (
  obj: Definition | Property
): obj is ObjectDefinition => 'type' in obj && obj.type === 'object';

export const isRequiringDependency = <T extends string>(
  obj: Dependency<T>
): obj is RequiringDependency<T> => Array.isArray(obj);

export const isConditionalDependency = <T extends string>(
  obj: Dependency<T>
): obj is ConditionalDependency<T> => 'properties' in obj;

export const isConditionalEnumDependency = <T extends string>(
  obj: Dependency<T>
): obj is ConditionalEnumDependency<T> => 'oneOf' in obj;

export const isObjectSchema = (
  schema: FormBuilderSchema
): schema is FormBuilderObjectSchema => schema.type === 'object';
