import { FormEntry, FormEntryType, FormSelectEntry } from 'src/types';

const enumIsValid = (enums: FormSelectEntry['enum']) => {
  const isEmpty = !enums.length;
  const hasEmpty = enums.includes('');
  const hasDuplicates = () =>
    enums.some((value, index) => enums.indexOf(value) !== index);
  return !isEmpty && !hasEmpty && !hasDuplicates();
};

export const validateEntry = (entry: FormEntry) =>
  !!entry.name &&
  (entry.type !== FormEntryType.Reference || !!entry.$ref) &&
  (entry.type !== FormEntryType.Select || enumIsValid(entry.enum ?? [])) &&
  (entry.type !== FormEntryType.Array || !!entry.items?.$ref);
