import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  Definition,
  FormBuilderSchema,
  FormEntry,
  JsonSchema,
} from 'src/types';
import { EntryPlaceholderField } from './entry-form/entry-common-fields';
import { TypeSpecificFields } from './entry-form/type-specific-fields';
import { EntryFormBase } from './entry-form/types';
import {
  entryToSchemaDefinition,
  schemaPropToFormEntry,
} from './entry-form/utils';

interface SimpleFormProps {
  schema: FormBuilderSchema;
  setSchema: Dispatch<SetStateAction<FormBuilderSchema>>;
}

export const SimpleForm: React.FC<SimpleFormProps> = ({
  schema,
  setSchema,
}) => {
  const entry = schemaPropToFormEntry(
    schema as Definition,
    'schema',
    schema.uiSchema ?? {}
  );

  const updateSchema: EntryFormBase<FormEntry>['onChange'] = useCallback(
    (getNewEntryValue) => {
      const newEntryValue = getNewEntryValue(entry);
      const { definition, uiSchema } = entryToSchemaDefinition(newEntryValue);
      setSchema((current) => ({
        ...current,
        ...(definition as JsonSchema),
        uiSchema,
      }));
    },
    [entry, setSchema]
  );

  return (
    <>
      <TypeSpecificFields entry={entry} setEntry={updateSchema} />
      <EntryPlaceholderField entry={entry} setEntry={updateSchema} />
    </>
  );
};
