import { useCallback } from 'react';
import { EditSampleTypeDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useUpdateSampleType = (sampleTypeId: EditSampleTypeDTO['id']) => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'PUT:/api/SampleType/UpdateSampleType',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: Partial<EditSampleTypeDTO>) => {
      dispatch({ data: { id: sampleTypeId, ...data } });
    },
    [dispatch, sampleTypeId]
  );

  return { dispatch: dispatchWrapper, result };
};
