import { omit } from '@tsp/shared-utils';
import { entryToSchemaDefinition } from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { Dependency, DependencyTypeEnum, FormEntry } from 'src/types';
import {
  isConditionalDependency,
  isConditionalEnumDependency,
  isRequiringDependency,
} from 'src/types/type-utils';

const { Requiring, Conditional } = DependencyTypeEnum;

export const entryToSchemaDependency = (
  entry: FormEntry,
  existingDependency?: Dependency
): Dependency | null => {
  const {
    name: entryName,
    definition,
    dependency,
  } = entryToSchemaDefinition(entry);

  if (!dependency) {
    return null;
  }

  if (dependency.type === Requiring) {
    const existingValue =
      existingDependency && isRequiringDependency(existingDependency)
        ? existingDependency
        : undefined;
    return existingValue
      ? existingValue.includes(entryName)
        ? existingValue
        : [...existingValue, entryName]
      : [entryName];
  }

  if (dependency.type === Conditional && !dependency?.value) {
    const existingProperties =
      existingDependency && isConditionalDependency(existingDependency)
        ? existingDependency.properties
        : undefined;
    return {
      properties: { ...existingProperties, [entryName]: definition },
    };
  }

  if (dependency.type === Conditional && dependency.name && dependency.value) {
    const existingDefs =
      existingDependency && isConditionalEnumDependency(existingDependency)
        ? existingDependency.oneOf
        : [];

    const cleanDefs = existingDefs.map(({ properties }) => ({
      properties:
        entryName in properties ? omit(properties, entryName) : properties,
    }));

    const existingDef = cleanDefs.find(({ properties }) => {
      const condition = properties[dependency.name];
      return (
        condition &&
        'enum' in condition &&
        condition.enum.includes(`${dependency.value}`)
      );
    });

    return {
      oneOf: [
        ...cleanDefs,
        {
          properties: {
            ...existingDef?.properties,
            [entryName]: definition,
            [dependency.name]: { enum: [dependency.value] },
          },
        },
      ],
    };
  }

  return null;
};
