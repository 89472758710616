import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Divider,
  Icons,
} from '@innoit/ui-components';
import { useState } from 'react';
import { JsonForm } from 'src/components/json-form';
import { useTranslation } from 'src/i18n';
import { FormBuilderSchema } from 'src/types';
import { stringifySchemaForPreview } from 'src/util/stringify-schema-for-preview';

interface PreviewDialogProps extends Omit<DialogProps, 'title'> {
  schema: FormBuilderSchema;
  title?: DialogProps['title'];
  onSubmit: () => void;
}

export const PreviewDialog: React.FC<PreviewDialogProps> = ({
  schema,
  onSubmit,
  onClose,
  title,
  ...dialogProps
}) => {
  const { t } = useTranslation();
  const [viewAsJson, setViewAsJson] = useState(false);

  return (
    <Dialog size="md" title={title ?? null} onClose={onClose} {...dialogProps}>
      <Button
        variant="text"
        fullWidth
        icon={Icons.SwitchAccessShortcut}
        onClick={() => setViewAsJson((current) => !current)}
      >
        {viewAsJson
          ? t('jsf:form-preview.viewAsHtml')
          : t('jsf:form-preview.viewAsJson')}
      </Button>
      <Box mt={6}>
        {viewAsJson ? (
          // TODO: add a Copy button
          <Box component="pre">{stringifySchemaForPreview(schema)}</Box>
        ) : (
          <JsonForm schema={schema} uiSchema={schema.uiSchema} liveValidate>
            &nbsp;
          </JsonForm>
        )}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between">
        <Button onClick={onClose} color="secondary">
          {t('jsf:button.cancel')}
        </Button>
        <Button onClick={onSubmit}>{t('jsf:form-builder.saveForm')}</Button>
      </Box>
    </Dialog>
  );
};
