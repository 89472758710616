import { useCallback, useEffect } from 'react';
import {
  FormComponentDTO,
  FormComponentStatusEnum,
  FormPurposeEnum,
} from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetFormComponentsWithStatusAndPurpose = ({
  status: statuses,
  purpose,
}: {
  status: Array<FormComponentStatusEnum>;
  purpose: FormPurposeEnum;
}) => {
  const { dispatch, result } = useApiWrapper<
    Array<FormComponentDTO>,
    'formComponents'
  >(
    'POST:/api/FormCreator/GetFormComponentsWithStatusAndPurpose',
    'formComponents'
  );

  const safeStatuses = JSON.stringify(statuses);

  const dispatchWrapper = useCallback(() => {
    dispatch({
      data: JSON.parse(safeStatuses),
      params: { purpose },
    });
  }, [dispatch, purpose, safeStatuses]);

  useEffect(() => {
    dispatchWrapper();
  }, [dispatchWrapper]);

  return { ...result, refetch: dispatchWrapper };
};
