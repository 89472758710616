import { I18n, Locale } from '@innoit/localization';
import { contextNotInitialized } from '@tsp/shared-utils';
import { createContext, ReactNode, useCallback } from 'react';
import {
  I18nProvider,
  TranslateFn,
  TranslationKey,
  useTranslation,
} from 'src/i18n';

type TranslationContext = 'schemaForm' | 'validationError';

interface IJsonFormContext {
  translate: (
    key: string,
    context: TranslationContext,
    locale?: Locale
  ) => ReturnType<TranslateFn>;
  loadLanguage: (locale: Locale) => Promise<Locale>;
}

export const JsonFormContext = createContext<IJsonFormContext>({
  translate: contextNotInitialized,
  loadLanguage: contextNotInitialized,
});

interface JsonFormsBaseProps {
  /**
   * If you're using i18n for translating your application,
   * please provide the i18n/i18next instance here
   */
  i18nInstance?: I18n;
  /**
   * If you use localization, you need to take care of the
   * translation strings for all the titles, descriptions and enums
   * in your forms. Then just provide the i18n namespace of these
   */
  trNamespace?: string;
  /**
   * If your jsf translation strings are nested, you will need to
   * provide the translations scope (the translation key prefix)
   */
  trKeyPrefix?: string;
  /**
   * Use `lowercaseTrKey` to instruct that the jsf translation keys
   * are still the same as the form strings, but lowercased
   */
  lowercaseTrKey?: boolean;
  /**
   * Use `translate` if you need to provide a completely custom
   * translation rules. Be aware that you will also need to provide
   * custom translation for the errors
   */
  translate?: (
    key: string,
    context: TranslationContext,
    lang: Locale
  ) => string;
  /**
   * The `JsonFormsBase` should contain all of your app code that
   * will be using the Json forms
   */
  children: ReactNode;
}

export const JsonFormsBase: React.FC<JsonFormsBaseProps> = ({
  children,
  i18nInstance,
  trKeyPrefix = '',
  trNamespace = 'jsf',
  lowercaseTrKey = false,
  translate: externalTranslate,
}) => {
  const { t, i18n } = useTranslation();

  const preProcessTrKey = useCallback(
    (key: string) => {
      const trKey = lowercaseTrKey ? key.toLocaleLowerCase() : key;
      const prefix = trKeyPrefix ? `${trKeyPrefix}.` : '';
      return `${trNamespace}:${prefix}${trKey}` as TranslationKey;
    },
    [lowercaseTrKey, trKeyPrefix, trNamespace]
  );

  const translate = useCallback<IJsonFormContext['translate']>(
    (key, context, lng = i18n.language as Locale) =>
      externalTranslate
        ? externalTranslate(key, context, lng)
        : t(
            context === 'schemaForm'
              ? preProcessTrKey(key)
              : (`jsf:ajvErrors.${key}` as TranslationKey),
            undefined,
            { lng, defaultValue: key }
          ),
    [externalTranslate, i18n.language, preProcessTrKey, t]
  );

  const loadLanguage = useCallback<IJsonFormContext['loadLanguage']>(
    async (locale) => {
      if (!i18n.hasResourceBundle(locale, trNamespace)) {
        await i18n.loadLanguages([locale]);
      }
      return locale;
    },
    [i18n, trNamespace]
  );

  if (externalTranslate && (trKeyPrefix || trNamespace || lowercaseTrKey)) {
    console.warn(
      '`translate` method provided, so `trKeyPrefix`, `trNamespace` and `lowercaseTrKey` will be ignored'
    );
  }

  return (
    <I18nProvider fallback={false} instance={i18nInstance}>
      <JsonFormContext.Provider
        value={{
          translate,
          loadLanguage,
        }}
      >
        {children}
      </JsonFormContext.Provider>
    </I18nProvider>
  );
};
