import { useCallback } from 'react';
import { AddSampleTypeDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useCreateSampleType = () => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'POST:/api/SampleType/CreateSampleType',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: AddSampleTypeDTO) => {
      dispatch({ data });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result };
};
