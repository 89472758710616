import { Checkbox } from '@innoit/ui-components';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { FormCheckboxEntry } from 'src/types/form-entry';

export const EntryCheckboxFieldsForm: React.FC<
  EntryFormBase<FormCheckboxEntry>
> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  return (
    <Checkbox
      label={t('jsf:form-builder.checkedByDefault')}
      checked={props.default ?? false}
      onChange={(checked) =>
        onChange((current) => ({ ...current, default: checked }))
      }
    />
  );
};
