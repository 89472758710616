import { Box, TextField } from '@innoit/ui-components';
import { EntryRefFieldsForm } from 'src/components/form-builder/form-builder-form/entry-form/type-specific-fields/_entry-ref-fields';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import {
  FormArrayEntry,
  FormEntryType,
  FormReferenceEntry,
} from 'src/types/form-entry';

export const EntryArrayFieldsForm: React.FC<EntryFormBase<FormArrayEntry>> = ({
  onChange,
  ...entryProps
}) => {
  const { t } = useTranslation();
  const items =
    entryProps.items ?? ({} as NonNullable<FormArrayEntry['items']>);
  return (
    <>
      <EntryRefFieldsForm
        $ref={items.$ref}
        type={FormEntryType.Reference}
        onChange={(callback) =>
          onChange((current) => ({
            ...current,
            items: {
              ...items,
              $ref: callback(items as FormReferenceEntry).$ref,
            },
          }))
        }
        required
      />
      <Box display="flex" gap={2}>
        <TextField
          sx={{ flexBasis: '50%' }}
          type="number"
          label={t('jsf:form-builder.minimumItemsRequired')}
          value={items.minItems ?? 0}
          InputProps={{ inputProps: { minimum: 0, maximum: items.maxItems } }}
          onChange={(value) =>
            onChange((current) => ({
              ...current,
              items: { ...items, minItems: parseInt(value) },
            }))
          }
        />
        <TextField
          sx={{ flexBasis: '50%' }}
          type="number"
          label={t('jsf:form-builder.maximumItemsPermitted')}
          value={items.maxItems ?? 10}
          InputProps={{ inputProps: { minimum: items.maxItems } }}
          onChange={(value) =>
            onChange((current) => ({
              ...current,
              items: { ...items, maxItems: parseInt(value) },
            }))
          }
        />
      </Box>
    </>
  );
};
