import { useCallback } from 'react';
import { FormComponentDTO, UpdateFormComponentStatusDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useUpdateFormComponentStatus = () => {
  const { dispatch, result, reset } = useApiWrapper<
    FormComponentDTO,
    'statusMsg'
  >('PATCH:/api/FormCreator/UpdateFormComponentStatus', 'statusMsg');

  const dispatchWrapper = useCallback(
    (data: UpdateFormComponentStatusDTO) => {
      dispatch({ data });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result, reset };
};
