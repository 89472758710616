import { Select } from '@innoit/ui-components';
import { useContext } from 'react';
import { DefinitionsContext } from 'src/components/form-builder/definitions-context';
import { EntryRefFieldsForm } from 'src/components/form-builder/form-builder-form/entry-form/type-specific-fields/_entry-ref-fields';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { getReferenceName } from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { useTranslation } from 'src/i18n';
import { EnumDefinition } from 'src/types';
import {
  FormEntryType,
  FormMultiSelectEntry,
  FormReferenceEntry,
} from 'src/types/form-entry';
import { isEnumDefinition } from 'src/types/type-utils';

export const EntryMultiSelectFieldsForm: React.FC<
  EntryFormBase<FormMultiSelectEntry>
> = ({ onChange, ...entryProps }) => {
  const { t } = useTranslation();
  const items =
    entryProps.items ?? ({} as NonNullable<FormMultiSelectEntry['items']>);
  const { definitions } = useContext(DefinitionsContext);

  const selectedDef = definitions[getReferenceName(items)] as
    | EnumDefinition
    | undefined;

  return (
    <>
      <EntryRefFieldsForm
        $ref={items.$ref}
        type={FormEntryType.Reference}
        filter={isEnumDefinition}
        onChange={(callback) =>
          onChange((current) => ({
            ...current,
            items: {
              ...items,
              $ref: callback(items as FormReferenceEntry).$ref,
            },
          }))
        }
        required
      />
      <Select
        multiple
        label={t('jsf:form-builder.defaultValue')}
        options={(selectedDef?.enum ?? []).map((value) => ({
          value,
        }))}
        onChange={(values: EnumDefinition['enum']) =>
          onChange((current) => ({ ...current, default: values }))
        }
      />
    </>
  );
};
