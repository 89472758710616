import { Stack, TextField } from '@innoit/ui-components';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { FormTextEntry } from 'src/types/form-entry';

export const EntryTextFieldsForm: React.FC<EntryFormBase<FormTextEntry>> = ({
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const minLength = props.minLength ?? 0;
  const maxLength = props.maxLength ?? Infinity;

  return (
    <>
      <Stack spacing={2} direction="row">
        <TextField
          label={t('jsf:form-builder.minLength')}
          type="number"
          sx={{ flex: 1 }}
          value={props.minLength}
          onChange={(value) =>
            onChange((current) => ({ ...current, minLength: parseInt(value) }))
          }
          InputProps={{ inputProps: { min: 0, max: props.maxLength } }}
          error={minLength < 0}
        />
        <TextField
          label={t('jsf:form-builder.maxLength')}
          type="number"
          sx={{ flex: 1 }}
          value={props.maxLength}
          onChange={(value) =>
            onChange((current) => ({ ...current, maxLength: parseInt(value) }))
          }
          InputProps={{ inputProps: { min: minLength } }}
          error={minLength > maxLength}
        />
      </Stack>
      <TextField
        label={t('jsf:form-builder.defaultValue')}
        type="text"
        value={props.default}
        onChange={(value) =>
          onChange((current) => ({ ...current, default: value || undefined }))
        }
        InputProps={{
          inputProps: {
            minLength: minLength,
            maxLength: maxLength,
          },
        }}
        error={
          (props.default?.length ?? minLength) < minLength ||
          (props.default?.length ?? maxLength) > maxLength
        }
      />
    </>
  );
};
