import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { DefinitionsContext } from 'src/components/form-builder/definitions-context';
import { ObjectForm } from 'src/components/form-builder/form-builder-form/object-form';
import { SimpleForm } from 'src/components/form-builder/form-builder-form/simple-form';
import { FormBuilderSchema } from 'src/types';
import { isObjectSchema } from 'src/types/type-utils';

interface FormBuilderFormProps {
  schema: FormBuilderSchema;
  setSchema: Dispatch<SetStateAction<FormBuilderSchema>>;
}

export const FormBuilderForm: React.FC<FormBuilderFormProps> = ({
  schema,
  setSchema,
}) => {
  const { getDefinitionsForSchema } = useContext(DefinitionsContext);
  const updateSchema: typeof setSchema = useCallback(
    (valueOrGetter) => {
      const newSchema =
        typeof valueOrGetter === 'function'
          ? valueOrGetter(schema)
          : valueOrGetter;

      const definitions = getDefinitionsForSchema(newSchema);

      setSchema({ ...newSchema, definitions });
    },
    [getDefinitionsForSchema, schema, setSchema]
  );

  return isObjectSchema(schema) ? (
    <ObjectForm schema={schema} setSchema={updateSchema} />
  ) : (
    <SimpleForm schema={schema} setSchema={updateSchema} />
  );
};
