import { StatusMessage } from '@innoit/ui-components';
import { useCallback } from 'react';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { FormEntry, FormEntryType } from 'src/types';
import { EntryArrayFieldsForm } from './_entry-array-fields';
import { EntryCheckboxFieldsForm } from './_entry-checkbox-fields';
import { EntryDateFieldsForm } from './_entry-date-fields';
import { EntryMultiSelectFieldsForm } from './_entry-multi-select-fields';
import { EntryNumberFieldsForm } from './_entry-number-fields';
import { EntryRefFieldsForm } from './_entry-ref-fields';
import { EntrySelectFieldsForm } from './_entry-select-fields';
import { EntryTextFieldsForm } from './_entry-text-fields';

interface TypeSpecificFieldsProps {
  entry: FormEntry;
  setEntry: EntryFormBase<FormEntry>['onChange'];
}

export const TypeSpecificFields: React.FC<TypeSpecificFieldsProps> = ({
  entry,
  setEntry,
}) => {
  const _setEntry = useCallback(
    <T extends FormEntry>(callback: (current: T) => FormEntry) =>
      setEntry((current) => callback(current as T)),
    [setEntry]
  );

  return entry.type === FormEntryType.Checkbox ? (
    <EntryCheckboxFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Number ||
    entry.type === FormEntryType.Integer ? (
    <EntryNumberFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Text ? (
    <EntryTextFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Select ? (
    <EntrySelectFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.MultiSelect ? (
    <EntryMultiSelectFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Date ? (
    <EntryDateFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Reference ? (
    <EntryRefFieldsForm {...entry} onChange={_setEntry} />
  ) : entry.type === FormEntryType.Array ? (
    <EntryArrayFieldsForm {...entry} onChange={_setEntry} />
  ) : (
    <StatusMessage error>{`unknown type: ${entry.type}`}</StatusMessage>
  );
};
