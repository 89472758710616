import {
  Box,
  Button,
  IconButton,
  Icons,
  Label,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@innoit/ui-components';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { EnumDefinition, EnumDefinitionFormat } from 'src/types';
import { FormSelectEntry } from 'src/types/form-entry';

interface OptionsListProps {
  enumValues: EnumDefinition['enum'];
  onChange: (values: EnumDefinition['enum']) => void;
}

interface OptionItemProps {
  value: OptionsListProps['enumValues'][number];
  label: string;
  onChange: (value: string) => void;
  onRemove: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  duplicateOf?: string;
}

const emptyValue = '';

function swapValues<T>(list: Array<T>, a: number, b: number) {
  return list.map((value, index) =>
    index === a ? list[b] : index === b ? list[a] : value
  );
}

const OptionsList: React.FC<OptionsListProps> = ({
  enumValues,
  onChange,
}: OptionsListProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={3} sx={{ alignItems: 'baseline' }}>
      <Label sx={{ alignSelf: 'baseline' }} bolded>
        {t('jsf:form-builder.optionsList')}
      </Label>

      {enumValues.map((value, index) => (
        <OptionItem
          key={index} // surprisingly that way of indexing the options works. However, if it ever fails, we should replace the indexes with unique identifiers bond to the option value
          value={value}
          label={t('jsf:field.optionN', { n: index + 1 })}
          onRemove={() => onChange(enumValues.filter((_, i) => i !== index))}
          onChange={(newValue) =>
            onChange(
              enumValues.map((current, i) => (i === index ? newValue : current))
            )
          }
          onMoveDown={
            index < enumValues.length - 1
              ? () => onChange(swapValues(enumValues, index, index + 1))
              : undefined
          }
          onMoveUp={
            index > 0
              ? () => onChange(swapValues(enumValues, index - 1, index))
              : undefined
          }
          duplicateOf={
            enumValues.indexOf(value) !== index
              ? t('jsf:field.optionN', { n: enumValues.indexOf(value) + 1 })
              : undefined
          }
        />
      ))}

      <Button
        variant="text"
        sx={{ ml: 'auto', height: 'auto' }}
        onClick={() => onChange(enumValues.concat([emptyValue]))}
        startIcon={<Icons.Add />}
        disabled={enumValues.includes(emptyValue)}
      >
        {t('jsf:action.add')}
      </Button>
    </Stack>
  );
};

const OptionItem: React.FC<OptionItemProps> = ({
  value,
  label,
  onMoveUp,
  onMoveDown,
  onRemove,
  onChange,
  duplicateOf,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" width="100%">
      <TextField
        fullWidth
        label={label}
        value={value}
        onChange={onChange}
        error={!!duplicateOf}
        helperText={
          duplicateOf
            ? t('jsf:ajvErrors.uniqueItems', { i: label, j: duplicateOf })
            : undefined
        }
        required
      />
      <IconButton
        icon={Icons.ArrowUpward}
        onClick={onMoveUp ?? (() => undefined)}
        disabled={!onMoveUp}
        title={t('jsf:action.moveOneUp')}
      />
      <IconButton
        icon={Icons.ArrowDownward}
        onClick={onMoveDown ?? (() => undefined)}
        disabled={!onMoveDown}
        title={t('jsf:action.moveOneDown')}
      />
      <IconButton
        icon={Icons.Remove}
        onClick={onRemove}
        title={t('jsf:action.delete')}
      />
    </Box>
  );
};
export const EntrySelectFieldsForm: React.FC<
  EntryFormBase<FormSelectEntry>
> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const { enum: enums = [emptyValue] } = props;

  return (
    <>
      <OptionsList
        enumValues={enums}
        onChange={(newValues) =>
          onChange((current) => ({
            ...current,
            enum: newValues,
            enumNames: undefined, // do not use the enumNames (unnecessary duplication of data and increased schema volume)
          }))
        }
      />

      <RadioGroup
        groupLabel={t('jsf:form-builder.format')}
        defaultValue={props.format ?? EnumDefinitionFormat.Dropdown}
        options={[
          {
            label: t('jsf:enums.EnumDefinitionFormat.dropdown'),
            value: EnumDefinitionFormat.Dropdown,
          },
          {
            label: t('jsf:enums.EnumDefinitionFormat.radio'),
            value: EnumDefinitionFormat.Radio,
          },
        ]}
        onChange={(value) =>
          onChange((current) => ({
            ...current,
            format: value as EnumDefinitionFormat,
          }))
        }
      />

      <Select
        label={t('jsf:form-builder.defaultValue')}
        value={props.default}
        options={enums.map((value) => ({ value }))}
        onChange={(value: FormSelectEntry['default']) =>
          onChange((current) => ({ ...current, default: value || undefined }))
        }
      />
    </>
  );
};
