import { useCallback } from 'react';
import { CreateFormComponentDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useCreateFormComponent = () => {
  const { dispatch, result, reset } = useApiWrapper<string, 'statusMsg'>(
    'POST:/api/FormCreator/CreateFormComponent',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: CreateFormComponentDTO) => {
      dispatch({ data });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result, reset };
};
