import { TextField } from '@innoit/ui-components';
import { useTranslation } from 'src/i18n';
import { FormEntry } from 'src/types/form-entry';
import { EntryTypeField, EntryTypeFieldProps } from './entry-common-fields';
import { EntryFormBase } from './types';

const formatName = (name: string) =>
  name
    .toLocaleLowerCase()
    .replaceAll(/\W/g, '-')
    .replaceAll(/-+/g, '-')
    .replaceAll(/^-|-$/g, '')
    .concat('-')
    .concat(Date.now().toString());

type EntryFormHeaderProps = EntryFormBase<FormEntry> & {
  typeFieldProps?: Omit<EntryTypeFieldProps, 'value' | 'onChange'>;
  isNewEntry?: boolean;
};

export const EntryFormHeader: React.FC<EntryFormHeaderProps> = ({
  onChange,
  typeFieldProps = {},
  isNewEntry,
  ...entry
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t('jsf:field.title')}
        value={entry.title}
        onChange={(title) =>
          onChange((current) => ({
            ...current,
            title,
            name: entry.dependency
              ? current.name
              : formatName(title ?? 'untitled-entry'),
          }))
        }
      />
      <TextField
        label={t('jsf:field.description')}
        value={entry.description}
        onChange={(description) =>
          onChange((current) => ({ ...current, description }))
        }
      />

      <EntryTypeField
        onChange={onChange}
        {...typeFieldProps}
        isNewEntry={isNewEntry}
        {...entry}
      />
    </>
  );
};
