import { Box, IconButton, Icons, Select } from '@innoit/ui-components';
import { useState } from 'react';
import { EntryTypeHelpDialog } from 'src/components/form-builder/dialogs/entry-type-help-dialog';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { DateDefinitionFormat } from 'src/types';
import { FormEntry, FormEntryType } from 'src/types/form-entry';

export interface EntryTypeFieldProps {
  label?: string;
  disabled?: boolean;
  typesToExclude?: Array<FormEntryType>;
  typesToInclude?: Array<FormEntryType>;
  isNewEntry?: boolean;
}

const allTypes = Object.values(FormEntryType);

const getTypeSpecificProps = (type: FormEntryType) => {
  switch (type) {
    case FormEntryType.Date:
      return { type, format: DateDefinitionFormat.Date };
    case FormEntryType.Select:
      return { type, enum: [] };
    case FormEntryType.MultiSelect:
      return { type, items: {}, uniqueItems: true };
    case FormEntryType.Array:
      return { type, items: {} };
    case FormEntryType.Reference:
      return { type, $ref: '' };
    default: {
      return { type };
    }
  }
};

export const EntryTypeField: React.FC<
  EntryFormBase<FormEntry> & EntryTypeFieldProps
> = ({
  label,
  typesToExclude = [
    FormEntryType.Form,
    FormEntryType.Null,
    FormEntryType.Integer,
  ],
  typesToInclude = allTypes.filter((type) => !typesToExclude?.includes(type)),
  onChange,
  disabled,
  ...entryProps
}) => {
  const { t } = useTranslation();
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const { type } = entryProps;

  const options = typesToInclude.map((value) => ({
    value,
    optionString: t(`jsf:enums.FormEntryType.${value}`),
  }));

  return (
    <Box display="flex" gap={2}>
      {showHelpDialog && (
        <EntryTypeHelpDialog onClose={() => setShowHelpDialog(false)} />
      )}
      <Select
        fullWidth
        required
        disabled={disabled}
        label={label ?? t('jsf:field.entryType')}
        value={type === FormEntryType.Integer ? FormEntryType.Number : type}
        options={options}
        onChange={(newValue: FormEntryType) => {
          onChange(
            ({ name, title, description }) =>
              ({
                name,
                title,
                description,
                ...getTypeSpecificProps(newValue),
              } as FormEntry)
          );
        }}
      />

      <IconButton
        icon={Icons.Help}
        onClick={() => setShowHelpDialog(true)}
        title={'jsf:action.help'}
      />
    </Box>
  );
};
