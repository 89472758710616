import { JsonFormsBase } from '@tsp/json-forms';
import { createContext, ReactNode } from 'react';
import { FormComponentContextProvider } from 'src/contexts/form-component-context';
import { SampleTypeContextProvider } from 'src/contexts/sample-type-context';
import { User } from 'src/contexts/session-context';
import { useTranslation } from 'src/i18n';

export const AppContext = createContext({});

const ProviderWhenAuthenticated: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();

  return (
    <AppContext.Provider value={{ sampleTypes: undefined }}>
      <JsonFormsBase
        i18nInstance={i18n}
        trNamespace="json-forms"
        lowercaseTrKey
      >
        <SampleTypeContextProvider>
          <FormComponentContextProvider>
            {children}
          </FormComponentContextProvider>
        </SampleTypeContextProvider>
      </JsonFormsBase>
    </AppContext.Provider>
  );
};

interface AppContextProviderProps {
  currentUser: User | undefined;
}

export const AppContextProvider: React.FC<
  AppContextProviderProps & { children: ReactNode }
> = ({ currentUser, children, ...props }) =>
  currentUser ? (
    <ProviderWhenAuthenticated {...props}>{children}</ProviderWhenAuthenticated>
  ) : (
    <>{children}</>
  );
