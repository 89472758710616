import { Autocomplete } from '@innoit/ui-components';
import { useContext } from 'react';
import { DefinitionsContext } from 'src/components/form-builder/definitions-context';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import {
  getReferenceName,
  getReferenceRef,
} from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { useTranslation } from 'src/i18n';
import { Definition } from 'src/types';
import { FormReferenceEntry } from 'src/types/form-entry';

export const EntryRefFieldsForm: React.FC<
  EntryFormBase<FormReferenceEntry> & {
    filter?: (def: Definition) => boolean;
  }
> = ({ filter, onChange, $ref }) => {
  const { t } = useTranslation();
  const { definitions } = useContext(DefinitionsContext);

  const names = Object.entries(definitions)
    .filter(([, definition]) => (filter ? filter(definition) : true))
    .map(([name]) => name);

  const defaultValue = $ref ? getReferenceName({ $ref }) : '';
  const noTitle = t('jsf:form-builder.noTitle');

  return (
    <Autocomplete
      required
      label={t('jsf:form-builder.selectDefinition')}
      options={[...names, '']}
      value={defaultValue}
      getOptionLabel={(name: string) => {
        // always use `title.trim() || name`, because title can be '' and even ' '
        const title = definitions[name]?.title?.trim();
        return name && definitions[name]
          ? `${title || noTitle} (${definitions[name].$id || name})`
          : '';
      }}
      onChange={(val) =>
        val &&
        typeof val === 'string' &&
        onChange((current) => {
          const refDefinition = definitions[val];
          return {
            ...current,
            $ref: getReferenceRef(val),
            title: refDefinition?.title ?? '',
            name: val,
          };
        })
      }
      multiple={false}
    />
  );
};
