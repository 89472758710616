import { Box, TooltipIcon, TooltipProps } from '@innoit/ui-components';
import { PropsWithChildren } from 'react';

interface TooltipBoxProps extends PropsWithChildren {
  tip: TooltipProps['title'];
  tooltipWidth?: number;
}

export const TooltipBox: React.FC<TooltipBoxProps> = ({
  children,
  tip,
  tooltipWidth = 240,
}) => (
  <Box display="flex" alignItems="center" gap={2}>
    {children}
    <TooltipIcon
      title={tip}
      tooltipProps={{ sx: { maxWidth: tooltipWidth } }}
    />
  </Box>
);
