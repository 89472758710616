import { getReferenceRef } from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { Definition, JsonSchema, RefProperty } from 'src/types';
import { isRefDefinition } from 'src/types/type-utils';

/* 
  Adds given definition to the schema's dependencies (unless 
  already added) and prepends a new reference prop, pointing 
  to that definition
*/
export const addDefinitionToSchema = (
  definitionId: string,
  definitionValue: Definition,
  schema: JsonSchema,
  nestedDefinitions: Record<string, Definition> = {}
): JsonSchema => {
  const ref = getReferenceRef(definitionId);
  const index = Object.values(schema.properties ?? {}).filter(
    (prop) => isRefDefinition(prop) && prop.$ref === ref
  ).length;
  const propKey = `${definitionId}-${index}`;
  const propValue: RefProperty = {
    title:
      definitionValue.title && index > 0
        ? `${definitionValue.title} ${index}`
        : definitionValue.title,
    $ref: ref,
  };

  const newDefinitions = Object.fromEntries(
    Object.entries(nestedDefinitions)
      .concat([[definitionId, definitionValue]])
      .filter(([defId]) => !schema.definitions?.[defId])
  );

  return {
    ...schema,
    definitions: {
      ...schema.definitions,
      ...newDefinitions,
    },
    properties: {
      ...schema.properties,
      [propKey]: propValue,
    },
  };
};
