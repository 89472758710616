import { AjvError } from '@rjsf/core';
import { TranslateFn, TranslationKey } from 'src/i18n';

/**
 * You can use this function to post-process the AJV form errors
 *
 * More info:
 *  - https://github.com/ajv-validator/ajv/tree/6a671057ea6aae690b5967ee26a0ddf8452c6297#validation-errors)
 *  - https://react-jsonschema-form.readthedocs.io/en/latest/usage/validation/#custom-error-messages
 *
 * @param errors an array of AJV error objects
 * @param translateFn the `t` function, returned by the `useTranslation` hook
 * @returns an adapted (filtered) array of translated AJV errors
 */
export const translateErrors = (
  errors: AjvError[],
  translateFn: TranslateFn
): AjvError[] => {
  return errors.map((error) => ({
    ...error,
    message: translateFn(error.name as TranslationKey, error.params),
  }));
};
