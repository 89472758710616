import { BasicSpinner } from '@innoit/basic-ui-components';
import { ApiServiceProvider } from '@tsp/api-hooks';
import { lazy, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from 'src/contexts/app-context';
import { SessionConsumer, SessionProvider } from 'src/contexts/session-context';
import { I18nProvider } from './i18n';

const App = lazy(() => import('./app'));

if (!process.env.REACT_APP_API_URL) {
  throw new Error('Missing REACT_APP_API_URL env variable!');
}

const apiUrl = process.env.REACT_APP_API_URL;

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <BrowserRouter>
      <SessionProvider>
        <SessionConsumer>
          {({ getAuthToken, currentUser, logout }) => (
            <ApiServiceProvider
              apiBaseUrl={apiUrl}
              getAuthToken={getAuthToken}
              onAuthError={logout}
            >
              <I18nProvider fallback={<BasicSpinner />}>
                <AppContextProvider currentUser={currentUser}>
                  <App />
                </AppContextProvider>
              </I18nProvider>
            </ApiServiceProvider>
          )}
        </SessionConsumer>
      </SessionProvider>
    </BrowserRouter>
  </StrictMode>
);
