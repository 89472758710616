import {
  ArrayDefinition,
  BooleanDefinition,
  DateDefinitionFormat,
  Definition,
  EnumDefinition,
  IntegerDefinition,
  NullDefinition,
  NumberDefinition,
  RefDefinition,
  StringDefinition,
} from 'src/types/definition';
import { DependencyTypeEnum } from 'src/types/dependency';
import { UiSchema } from 'src/types/json-schema';

export type EntryFormProps<T extends FormEntry> = Partial<T> & {
  type: T['type'];
  onChange: (callback: (current: T) => T) => void;
  onCancel?: () => void;
};

export enum FormEntryType {
  Form = 'Form',
  Array = 'Array',
  Checkbox = 'Checkbox',
  Date = 'Date',
  Integer = 'Integer',
  Null = 'Null',
  Number = 'Number',
  Reference = 'Reference',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Text = 'Text',
}

type FormEntryBase<T extends Definition> = Omit<T, 'type'> & {
  name: string;
  required?: boolean;
  uiSchema?: UiSchema;
  dependency?: {
    name: string;
    type: DependencyTypeEnum;
    value?: string;
  };
};

export type FormTextEntry = FormEntryBase<StringDefinition> & {
  type: FormEntryType.Text;
};

export type FormNumberEntry = FormEntryBase<NumberDefinition> & {
  type: FormEntryType.Number;
};

export type FormIntegerEntry = FormEntryBase<IntegerDefinition> & {
  type: FormEntryType.Integer;
};

export type FormDateEntry = FormEntryBase<StringDefinition> & {
  type: FormEntryType.Date;
  format: DateDefinitionFormat;
};

export type FormCheckboxEntry = FormEntryBase<BooleanDefinition> & {
  type: FormEntryType.Checkbox;
};

export type FormReferenceEntry = FormEntryBase<RefDefinition> & {
  type: FormEntryType.Reference;
};

export type FormNullEntry = FormEntryBase<NullDefinition> & {
  type: FormEntryType.Null;
};

export type FormArrayEntry = FormEntryBase<ArrayDefinition> & {
  type: FormEntryType.Array;
};

export type FormSelectEntry = FormEntryBase<EnumDefinition> & {
  type: FormEntryType.Select;
};

export type FormMultiSelectEntry = FormEntryBase<ArrayDefinition> & {
  type: FormEntryType.MultiSelect;
};

export type FormEntry =
  | FormArrayEntry
  | FormCheckboxEntry
  | FormDateEntry
  | FormIntegerEntry
  | FormNullEntry
  | FormNumberEntry
  | FormReferenceEntry
  | FormSelectEntry
  | FormMultiSelectEntry
  | FormTextEntry;
