import { TextField } from '@innoit/ui-components';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { TooltipBox } from 'src/components/shared/tooltip-box';
import { useTranslation } from 'src/i18n';
import { FormEntry, FormEntryType } from 'src/types/form-entry';

interface EntryPlaceholderFieldProps {
  entry: FormEntry;
  setEntry: EntryFormBase<FormEntry>['onChange'];
}

const textFieldTypes = [
  FormEntryType.Select,
  FormEntryType.Text,
  FormEntryType.Number,
  FormEntryType.Integer,
];

export const EntryPlaceholderField: React.FC<EntryPlaceholderFieldProps> = ({
  entry,
  setEntry,
}) => {
  const { t } = useTranslation();
  const entryName = entry.name;

  const { uiSchema } = entry;
  const isTextField = textFieldTypes.includes(entry.type);

  return isTextField ? (
    <TooltipBox tip={t('jsf:field.placeholderTooltip')}>
      <TextField
        fullWidth
        label={t('jsf:field.placeholder')}
        value={uiSchema?.[entryName]?.['ui:placeholder'] ?? ''}
        onChange={(placeholder) =>
          setEntry((current) => ({
            ...current,
            uiSchema: placeholder
              ? {
                  ...uiSchema,
                  'ui:placeholder': placeholder,
                }
              : uiSchema,
          }))
        }
      />
    </TooltipBox>
  ) : null;
};
