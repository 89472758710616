import { EnumDefinition } from 'src/types/definition';
import { Property } from 'src/types/property';

export enum DependencyTypeEnum {
  Conditional = 'Conditional',
  Requiring = 'Requiring',
}

export type RequiringDependency<T extends string = string> = Array<T>;

export interface ConditionalDependency<T extends string = string> {
  properties: Record<T, Property>;
  required?: Array<T>;
}

export interface ConditionalEnumDependency<T extends string = string> {
  oneOf: Array<{
    properties: Record<T, Pick<EnumDefinition, 'enum'> | Property>;
    required?: Array<T>;
  }>;
}

export type Dependency<T extends string = string> =
  | RequiringDependency<T>
  | ConditionalDependency<T>
  | ConditionalEnumDependency<T>;
