import { DrawerDialog } from '@innoit/ui-components';
import { EntryForm } from 'src/components/form-builder/form-builder-form/entry-form';
import { useTranslation } from 'src/i18n';
import { FormEntry } from 'src/types/form-entry';

interface EntryFormDialogProps {
  onSubmit: (props: FormEntry) => void;
  onClose: () => void;
  entry?: FormEntry;
}

export const EntryFormDialog: React.FC<EntryFormDialogProps> = ({
  onSubmit,
  onClose,
  entry,
}) => {
  const { t } = useTranslation();

  const dialogTitle = !entry
    ? t('jsf:form-builder.addEntry')
    : t('jsf:form-builder.updateEntry');

  return (
    <DrawerDialog title={dialogTitle} onClose={onClose}>
      <EntryForm entry={entry} onSubmit={onSubmit} onCancel={onClose} />
    </DrawerDialog>
  );
};
