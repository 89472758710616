import { JsonSchema } from 'src/types';

const translatableEntries: Array<keyof JsonSchema> = [
  'title',
  'description',
  'enumNames',
  'examples',
];

const isTranslatable = (val: keyof JsonSchema) =>
  translatableEntries.includes(val);

const isObject = (val: unknown) =>
  val && typeof val === 'object' && !Array.isArray(val);

/**
 * Use this function to translate the translatable properties of a JSON
 * schema object. These are: title, description, enumNames, examples
 * @param schema a JSON schema object
 * @param translateFn a translation function (should be able to provide
 *        the translation of a given translatable property value)
 * @returns the given JSON schema object with translated properties
 */
export const translateSchema = (
  schema: JsonSchema,
  translateFn: (value: string) => string
): JsonSchema => {
  const tWrapper = (value: unknown) =>
    typeof value === 'string' ? translateFn(value) : value;

  return Object.entries(schema).reduce((prev, [entryKey, entryValue]) => {
    const translatedValue = isTranslatable(entryKey as keyof JsonSchema)
      ? Array.isArray(entryValue)
        ? entryValue.map(tWrapper)
        : tWrapper(entryValue)
      : isObject(entryValue)
      ? translateSchema(entryValue, translateFn)
      : Array.isArray(entryValue)
      ? entryValue.map((val) =>
          typeof val === 'object' ? translateSchema(val, translateFn) : val
        )
      : entryValue;

    return {
      ...prev,
      [entryKey]: translatedValue,
    };
  }, {} as JsonSchema);
};
