import { Label, RadioGroup } from '@innoit/ui-components';
import { useTranslation } from 'src/i18n';
import { DependencyTypeEnum } from 'src/types/dependency';

interface DependencyTypeSelectProps {
  value: DependencyTypeEnum | undefined;
  onChange: (value: DependencyTypeEnum) => void;
}

export const DependencyTypeSelect: React.FC<DependencyTypeSelectProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label
        bolded
        tooltipText={
          <dl style={{ margin: '.6rem .8rem' }}>
            <dt style={{ fontWeight: 'bold' }}>
              {t('jsf:form-builder.dependencyDialog.conditional')}
            </dt>
            <dd>
              {t(
                'jsf:form-builder.dependencyDialog.explanationOfConditionalDependency'
              )}
            </dd>
            <dt style={{ fontWeight: 'bold', marginTop: '1rem' }}>
              {t('jsf:form-builder.dependencyDialog.requiring')}
            </dt>
            <dd>
              {t(
                'jsf:form-builder.dependencyDialog.explanationOfRequiringDependency'
              )}
            </dd>
          </dl>
        }
        tooltipProps={{ sx: { width: 400, textAlign: 'justify' } }}
      >
        {t('jsf:form-builder.dependencyDialog.dependencyType')}
      </Label>
      <RadioGroup
        value={value ?? ''}
        options={[
          {
            value: DependencyTypeEnum.Conditional,
            label: t('jsf:form-builder.dependencyDialog.conditional'),
          },
          {
            value: DependencyTypeEnum.Requiring,
            label: t('jsf:form-builder.dependencyDialog.requiring'),
          },
        ]}
        onChange={(val) => onChange(val as DependencyTypeEnum)}
      />
    </div>
  );
};
