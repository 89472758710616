import { Checkbox, Select } from '@innoit/ui-components';
import { useState } from 'react';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { DateDefinitionFormat } from 'src/types';
import { FormDateEntry } from 'src/types/form-entry';

enum DateValidationConstraint {
  BeforeCurrentDay = 'beforeCurrentDay',
  AfterCurrentDay = 'afterCurrentDay',
  Dynamic = 'dynamic',
  Undefined = 'undefined',
}

const currentDaytimeAdDefault = 'TODO: find how to implement';

const { BeforeCurrentDay, AfterCurrentDay, Undefined } =
  DateValidationConstraint;

export const EntryDateFieldsForm: React.FC<EntryFormBase<FormDateEntry>> = ({
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [validationConstraint, setValidationConstraint] =
    useState<DateValidationConstraint>(Undefined);

  return (
    <>
      <Select
        label={t('jsf:form-builder.format')}
        options={Object.values(DateDefinitionFormat).map((value) => ({
          value,
          optionString: t(`jsf:enums.DateDefinitionFormat.${value}`),
        }))}
        value={props.format}
        onChange={(format: DateDefinitionFormat) =>
          onChange((current) => ({ ...current, format }))
        }
        required
      />
      {/**
       * TODO: Implement the proposed solution:
       *       https://github.com/rjsf-team/react-jsonschema-form/issues/2118#issuecomment-1092966358
       *  */}
      <Select
        label={t('jsf:form-builder.useValidationConstraint')}
        value={validationConstraint}
        options={[
          {
            value: BeforeCurrentDay,
            optionString: t(
              'jsf:enums.DateValidationConstraint.beforeCurrentDay'
            ),
          },
          {
            value: AfterCurrentDay,
            optionString: t(
              'jsf:enums.DateValidationConstraint.afterCurrentDay'
            ),
          },
          {
            value: Undefined,
            optionString: t('jsf:enums.DateValidationConstraint.undefined'),
          },
        ]}
        onChange={setValidationConstraint}
        disabled
      />
      {/* 

        TODO: a solution is possible if we create a custom widget for date fields:

        https://github.com/rjsf-team/react-jsonschema-form/issues/2118#issuecomment-745742315
        
      */}
      <Checkbox
        label={t('jsf:form-builder.useCurrentDayTimeAsDefault')}
        value={props.default === currentDaytimeAdDefault}
        onChange={(val) =>
          onChange((current) => ({
            ...current,
            default: val ? currentDaytimeAdDefault : undefined,
          }))
        }
        disabled
      />
    </>
  );
};
