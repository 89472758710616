import { useCallback, useEffect } from 'react';
import { SampleTypeDTO, SampleTypeStatusEnum } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSampleTypesByStatus = (
  statuses?: Array<SampleTypeStatusEnum>
) => {
  const { dispatch, result } = useApiWrapper<
    Array<SampleTypeDTO>,
    'sampleTypes'
  >('POST:/api/SampleType/GetSampleTypesByStatus', 'sampleTypes');

  const safeStatuses = JSON.stringify(statuses);

  const dispatchWrapper = useCallback(
    (dispatchStatuses: Array<SampleTypeStatusEnum>) => {
      dispatch({ data: dispatchStatuses });
    },
    [dispatch]
  );

  useEffect(() => {
    safeStatuses && dispatchWrapper(JSON.parse(safeStatuses));
  }, [dispatchWrapper, safeStatuses]);

  return { ...result, dispatch: dispatchWrapper };
};
