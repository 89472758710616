import { useCallback } from 'react';
import { UpdateFormComponentDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useUpdateFormComponent = (
  formComponentId: UpdateFormComponentDTO['formComponentId']
) => {
  const { dispatch, result, reset } = useApiWrapper<string, 'statusMsg'>(
    'PATCH:/api/FormCreator/UpdateFormComponent',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: Omit<UpdateFormComponentDTO, 'formComponentId'>) => {
      dispatch({ data: { formComponentId, ...data } });
    },
    [dispatch, formComponentId]
  );

  return { dispatch: dispatchWrapper, result, reset };
};
