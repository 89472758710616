import { useCallback } from 'react';
import { AssignFormComponentDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useAssignFormComponent = () => {
  const { dispatch, result, reset } = useApiWrapper<string, 'statusMsg'>(
    'PUT:/api/SampleType/AssignFormComponent',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (data: Partial<AssignFormComponentDTO>) => {
      dispatch({ data });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result, reset };
};
