import { useCallback, useEffect } from 'react';
import { FormComponentDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetFormComponent = (
  formComponentId: FormComponentDTO['id']
) => {
  const { dispatch, result } = useApiWrapper<FormComponentDTO, 'formComponent'>(
    'GET:/api/FormCreator/GetFormComponent',
    'formComponent'
  );

  const dispatchWrapper = useCallback(
    (id: FormComponentDTO['id']) => {
      dispatch({ params: { id } });
    },
    [dispatch]
  );

  useEffect(() => {
    formComponentId && dispatchWrapper(formComponentId);
  }, [dispatchWrapper, formComponentId]);

  return {
    dispatch: dispatchWrapper,
    ...result,
  };
};
