/**
 * Note:
 *   This file has been generated an automated script.
 *   Please do not edit directly!
 */

import { I18n, I18nOptions, initI18n, Locale } from '@innoit/localization';
import React, { Suspense, SuspenseProps, useEffect, useState } from 'react';
import {
  Namespace,
  TFuncKey,
  TFunction,
  useTranslation as useTranslationOrig,
} from 'react-i18next';

export type TranslationNamespace = Exclude<Namespace, Array<unknown>>;
export type TranslationKey = TFuncKey<Namespace, true>;

type TOptions = Exclude<Parameters<TFunction>[2], string | undefined>;
export type TranslationParams = object;
export type TranslationOptions = Omit<TOptions, keyof TranslationParams>;

export type TranslateFn = (
  key: TranslationKey,
  params?: TranslationParams,
  options?: TranslationOptions
) => string;

export type AppLocale = Extract<
  Locale,
  | 'cs'
  | 'da'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it'
  | 'ja'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'tr'
  | 'uk'
  | 'zh'
>;

const readLocale: I18nOptions['readLocale'] = (locale, namespace, callback) => {
  import(`./locales/${namespace}/${locale}.json`)
    .then((resources) => callback(null, resources.default))
    .catch((error) => callback(error, null));
};

let i18n: I18n;

export const fallbackLanguage: AppLocale = 'en';
export const supportedLanguages: Array<AppLocale> = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'pl',
  'pt',
  'ru',
  'tr',
  'uk',
  'zh',
];
export const namespaces: Array<TranslationNamespace> = [
  'api-errors',
  'api-responses',
  'common',
  'default',
  'error-page',
  'faq',
  'form-builder',
  'form-component',
  'home-page',
  'json-forms',
  'login-page',
  'sample-type',
];

export const useTranslation = (
  ns?: Namespace
): {
  t: TranslateFn;
  i18n: I18n;
} => {
  return useTranslationOrig(ns, {
    useSuspense: true,
    i18n,
  });
};

export const I18nProvider: React.FC<
  {
    fallback: SuspenseProps['fallback'];
    children: React.ReactNode;
  } & Partial<I18nOptions>
> = ({ fallback, children, ...options }) => {
  const [initialized, setInitialized] = useState(i18n?.isInitialized);

  useEffect(() => {
    i18n = initI18n({
      readLocale,
      ns: namespaces,
      supportedLngs: supportedLanguages,
      fallbackLng: fallbackLanguage,
      ...options,
    });
    i18n.on('initialized', () => {
      setInitialized(true);
    });
    return () => {
      // avoid annoying warnings by canceling the state updates upon unmount
      i18n.off('initialized');
      // Since i18n is a global object, we need to manually remove all the
      // added namespaces upon unmount (to avoid duplicating them on next mount).
      // Note, however, that this is needed only in the nested I18nProviders
      // (hence the `!!options.instance` condition)
      options.instance &&
        namespaces.forEach((ns) => {
          i18n.languages.forEach((lang) => i18n.removeResourceBundle(lang, ns));
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialized ? (
    <Suspense fallback={fallback}>{children}</Suspense>
  ) : (
    <>{fallback}</>
  );
};
