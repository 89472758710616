import { Box, Button, Dialog } from '@innoit/ui-components';
import { useTranslation } from 'src/i18n';

export const TypeChangeConfirmationDialog = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t('jsf:misc.warning')} onClose={onCancel}>
      {t('jsf:misc.typeChangeWarning')}
      <Box display="flex" justifyContent="space-between" mt={5}>
        <Button color="secondary" onClick={onCancel}>
          {t('jsf:button.cancel')}
        </Button>
        <Button onClick={onConfirm}>{t('jsf:button.yes')}</Button>
      </Box>
    </Dialog>
  );
};
