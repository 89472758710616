import { contextNotInitialized, pick } from '@tsp/shared-utils';
import React, { createContext, ReactNode } from 'react';
import {
  castToSchemaProperties,
  getDependencyProperties,
  getReferenceName,
} from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { Definition, JsonSchema } from 'src/types';
import {
  isArrayDefinition,
  isConditionalEnumDependency,
  isObjectDefinition,
  isRefDefinition,
  isRequiringDependency,
} from 'src/types/type-utils';

const getRecursiveDefinitions = (
  def: Definition,
  allDefinitions: Record<string, Definition>
): Array<string> => {
  if (isArrayDefinition(def)) {
    const next = getReferenceName(def.items);
    return [
      next,
      ...getRecursiveDefinitions(allDefinitions[next], allDefinitions),
    ];
  }

  /**
   * Learn more about schema dependencies here:
   * https://react-jsonschema-form.readthedocs.io/en/latest/usage/dependencies/
   * */
  if (isObjectDefinition(def)) {
    const dependencyProperties = Object.values(def.dependencies ?? {}).flatMap(
      (dep) =>
        isConditionalEnumDependency(dep)
          ? dep.oneOf.flatMap((obj) =>
              Object.values(castToSchemaProperties(obj.properties))
            )
          : []
    );

    const references = Object.values(def.properties)
      .concat(dependencyProperties)
      .map((prop) =>
        isRefDefinition(prop)
          ? getReferenceName(prop)
          : isArrayDefinition(prop) && isRefDefinition(prop.items)
          ? getReferenceName(prop.items)
          : ''
      )
      .filter((ref) => ref);

    return references.flatMap((name) => [
      name,
      ...getRecursiveDefinitions(allDefinitions[name], allDefinitions),
    ]);
  }

  return [];
};

const getSchemaProperties = (
  schema: JsonSchema
): NonNullable<JsonSchema['properties']> =>
  Object.entries(schema.dependencies ?? {}).reduce(
    (result, [name, dependency]) =>
      isRequiringDependency(dependency)
        ? result
        : {
            ...result,
            ...getDependencyProperties(name, dependency),
          },
    schema.properties ?? {}
  );

const getDefinitionsForSchema = (
  schema: JsonSchema,
  allDefinitions: Record<string, Definition>
): Record<string, Definition> => {
  const definitionsNames = getRecursiveDefinitions(
    {
      type: 'object',
      properties:
        schema.type === 'object'
          ? getSchemaProperties(schema)
          : { schema: schema as Definition },
    },
    allDefinitions
  );

  return pick(allDefinitions, ...definitionsNames);
};

interface IDefinitionsContext {
  definitions: NonNullable<JsonSchema['definitions']>;
  getDefinitionsForSchema: (
    schema: JsonSchema
  ) => NonNullable<JsonSchema['definitions']>;
}

export const DefinitionsContext = createContext<IDefinitionsContext>({
  definitions: {},
  getDefinitionsForSchema: contextNotInitialized,
});

export const DefinitionsContextProvider: React.FC<
  Pick<IDefinitionsContext, 'definitions'> & { children: ReactNode }
> = ({ definitions, children }) => (
  <DefinitionsContext.Provider
    value={{
      definitions,
      getDefinitionsForSchema: (schema) =>
        getDefinitionsForSchema(schema, definitions),
    }}
  >
    {children}
  </DefinitionsContext.Provider>
);
