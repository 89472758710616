import { Box, Checkbox, RadioGroup, TextField } from '@innoit/ui-components';
import { useCallback, useState } from 'react';
import { EntryFormBase } from 'src/components/form-builder/form-builder-form/entry-form/types';
import { useTranslation } from 'src/i18n';
import { NumberDefinitionFormat } from 'src/types';
import { FormIntegerEntry, FormNumberEntry } from 'src/types/form-entry';

export const EntryNumberFieldsForm: React.FC<
  EntryFormBase<FormNumberEntry | FormIntegerEntry>
> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const [minValue, setMinValue] = useState(
    props.exclusiveMinimum ?? props.minimum
  );
  const [maxValue, setMaxValue] = useState(
    props.exclusiveMaximum ?? props.maximum
  );
  const [isMinValExclusive, setIsMinValExclusive] = useState(
    props.exclusiveMinimum !== undefined
  );
  const [isMaxValExclusive, setIsMaxValExclusive] = useState(
    props.exclusiveMaximum !== undefined
  );

  const minimum = props.minimum ?? props.exclusiveMinimum ?? -Infinity;
  const maximum = props.maximum ?? props.exclusiveMaximum ?? Infinity;

  const updateMinValue = useCallback(() => {
    onChange((current) => ({
      ...current,
      exclusiveMinimum: isMinValExclusive ? minValue : undefined,
      minimum: isMinValExclusive ? undefined : minValue,
    }));
  }, [isMinValExclusive, minValue, onChange]);

  const updateMaxValue = useCallback(() => {
    onChange((current) => ({
      ...current,
      exclusiveMaximum: isMaxValExclusive ? maxValue : undefined,
      maximum: isMaxValExclusive ? undefined : maxValue,
    }));
  }, [isMaxValExclusive, maxValue, onChange]);

  return (
    <>
      <RadioGroup
        groupLabel={t('jsf:form-builder.format')}
        defaultValue={props.format ?? NumberDefinitionFormat.Updown}
        options={[
          {
            label: t('jsf:enums.NumberDefinitionFormat.updown'),
            value: NumberDefinitionFormat.Updown,
          },
          {
            label: t('jsf:enums.NumberDefinitionFormat.range'),
            value: NumberDefinitionFormat.Range,
          },
        ]}
        onChange={(value) =>
          onChange((current) => ({
            ...current,
            format: value as NumberDefinitionFormat,
          }))
        }
        row
      />
      <Box display="flex" gap={1}>
        <TextField
          label={t('jsf:form-builder.minimum')}
          type="number"
          sx={{ flex: 1 }}
          value={props.minimum ?? props.exclusiveMinimum}
          onChange={(value) => {
            setMinValue(Number(value));
            updateMinValue();
          }}
          InputProps={{ inputProps: { maximum } }}
        />
        <Checkbox
          label={t('jsf:form-builder.exclusive')}
          checked={isMinValExclusive}
          onChange={(val) => {
            setIsMinValExclusive(val);
            updateMinValue();
          }}
        />
      </Box>
      <Box display="flex" gap={1}>
        <TextField
          label={t('jsf:form-builder.maximum')}
          type="number"
          sx={{ flex: 1 }}
          value={props.maximum ?? props.exclusiveMaximum}
          onChange={(value) => {
            setMaxValue(Number(value));
            updateMaxValue();
          }}
          InputProps={{ inputProps: { minimum } }}
          error={minimum > maximum}
        />
        <Checkbox
          label={t('jsf:form-builder.exclusive')}
          checked={isMaxValExclusive}
          onChange={(val) => {
            setIsMaxValExclusive(val);
            updateMaxValue();
          }}
        />
      </Box>
      <TextField
        label={t('jsf:form-builder.defaultValue')}
        type="number"
        value={props.default}
        onChange={(value) =>
          onChange((current) => ({
            ...current,
            default: value ? Number(value) : undefined,
          }))
        }
        InputProps={{ inputProps: { minimum, maximum } }}
        error={
          props.default !== undefined &&
          (props.default < minimum ||
            props.default <= (props.exclusiveMinimum ?? -Infinity) ||
            props.default > maximum ||
            props.default >= (props.exclusiveMaximum ?? Infinity))
        }
      />
      {/* <Checkbox
        label={'Value is integer'}
        checked={props.type === FormEntryType.Integer}
        onChange={(checked) =>
          onChange((current) => ({
            ...current,
            type: checked ? FormEntryType.Integer : FormEntryType.Number,
          }))
        }
      /> */}
    </>
  );
};
