import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  IconButtonProps,
  Icons,
} from '@innoit/ui-components';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DependenciesDialog } from 'src/components/form-builder/dialogs/dependencies-dialog';
import { EntryFormDialog } from 'src/components/form-builder/dialogs/entry-form-dialog';
import { useTranslation } from 'src/i18n';
import {
  FormBuilderObjectSchema,
  FormBuilderSchema,
  FormEntry,
  FormEntryType,
} from 'src/types';
import { isRequiringDependency } from 'src/types/type-utils';
import {
  entryToSchemaDefinition,
  schemaToFormEntries,
} from './entry-form/utils';
import { EntryPreview } from './entry-form/_entry-preview';
import { entryToSchemaDependency } from './utils/entry-to-schema-dependency';

export interface ObjectFormProps {
  schema: FormBuilderObjectSchema;
  setSchema: Dispatch<SetStateAction<FormBuilderSchema>>;
}

const AddNewEntryButton: React.FC<Pick<IconButtonProps, 'onClick'>> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      <IconButton
        icon={Icons.Add}
        title={t('jsf:action.insertNewEntry')}
        color="success"
        onClick={onClick}
      />
    </Box>
  );
};

export const ObjectForm: React.FC<ObjectFormProps> = ({
  schema,
  setSchema,
}) => {
  const { t } = useTranslation();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [insertAfterIndex, setInsertAfterIndex] = useState<number | null>(null);
  const [editDependenciesIndex, setEditDependenciesIndex] = useState<
    number | null
  >(null);
  const unsortedEntries = useMemo(() => schemaToFormEntries(schema), [schema]);
  const entriesOrder = schema.uiSchema?.['ui:order'] ?? [];

  const formEntries = unsortedEntries.sort(
    (entryA, entryB) =>
      entriesOrder.indexOf(entryB.name) - entriesOrder.indexOf(entryA.name)
  );

  const updateSchema = useCallback(
    (newFormEntries: Array<FormEntry>) => {
      setSchema((current) => {
        const properties: FormBuilderObjectSchema['properties'] = {};
        const formUiSchema: NonNullable<FormBuilderObjectSchema['uiSchema']> = {
          'ui:order': current?.uiSchema?.['ui:order'] ?? [],
        };
        const requiredFields: FormBuilderObjectSchema['required'] = [];
        const dependencies: FormBuilderObjectSchema['dependencies'] = {};

        newFormEntries.forEach((entry) => {
          const { name, definition, uiSchema, required, dependency } =
            entryToSchemaDefinition(entry);

          if (dependency) {
            const existingDependency = dependencies[dependency.name];
            const newDep = entryToSchemaDependency(entry, existingDependency);
            if (newDep) {
              dependencies[dependency.name] = newDep;
            }
            if (!newDep || isRequiringDependency(newDep)) {
              properties[name] = definition;
            }
          } else {
            properties[name] = definition;
          }

          if (!formUiSchema['ui:order']?.includes(name)) {
            formUiSchema['ui:order']?.push(name);
          }

          required && requiredFields.push(name);
          formUiSchema[name] = uiSchema;
        });
        return {
          ...current,
          properties,
          dependencies,
          required: requiredFields,
          uiSchema: formUiSchema,
        };
      });
    },
    [setSchema]
  );

  return (
    <div>
      <AddNewEntryButton onClick={() => setInsertAfterIndex(-1)} />
      {formEntries.map((entry, index) => (
        <EntryPreview key={index} entry={entry} readonly>
          <Box display="flex" alignItems="center">
            <IconButton
              icon={Icons.Edit}
              title={t('jsf:action.edit')}
              color="primary"
              onClick={() => setEditIndex(index)}
            />
            <IconButton
              icon={Icons.Delete}
              title={t('jsf:action.delete')}
              color="error"
              onClick={() =>
                updateSchema(
                  formEntries
                    .slice(0, index)
                    .concat(formEntries.slice(index + 1, formEntries.length))
                )
              }
            />
            <Badge
              sx={{ '& .MuiBadge-badge': { top: 10, right: 6 } }}
              variant="dot"
              color="info"
              invisible={!entry.dependency}
            >
              <IconButton
                icon={Icons.AddLink}
                title={t('jsf:form-builder.dependencyDialog.editDependencies')}
                color="warning"
                onClick={() => setEditDependenciesIndex(index)}
              />
            </Badge>
            <IconButton
              icon={Icons.ArrowUpward}
              title={t('jsf:action.moveOneUp')}
              color="secondary"
              disabled={index === 0}
              onClick={() =>
                updateSchema(
                  formEntries
                    .slice(0, index - 1)
                    .concat(formEntries[index], formEntries[index - 1])
                    .concat(formEntries.slice(index + 1, formEntries.length))
                )
              }
            />
            <IconButton
              icon={Icons.ArrowDownward}
              title={t('jsf:action.moveOneDown')}
              color="secondary"
              disabled={index === formEntries.length - 1}
              onClick={() =>
                updateSchema(
                  formEntries
                    .slice(0, index)
                    .concat(formEntries[index + 1], formEntries[index])
                    .concat(formEntries.slice(index + 2, formEntries.length))
                )
              }
            />
            <Box ml="auto">
              {entry.type !== FormEntryType.Reference && (
                <Checkbox
                  label="required"
                  labelPosition="left"
                  value={entry.required ?? false}
                  checked={entry.required ?? false}
                  onChange={(required) =>
                    updateSchema(
                      formEntries.map((_entry, _index) =>
                        _index === index ? { ..._entry, required } : _entry
                      )
                    )
                  }
                />
              )}
            </Box>
          </Box>
          <AddNewEntryButton onClick={() => setInsertAfterIndex(index)} />
        </EntryPreview>
      ))}

      {editDependenciesIndex !== null && (
        <DependenciesDialog
          onSubmit={(entry) => {
            updateSchema(
              formEntries.map((e, index) =>
                index === editDependenciesIndex ? entry : e
              )
            );
            setEditDependenciesIndex(null);
          }}
          onClose={() => setEditDependenciesIndex(null)}
          entry={formEntries[editDependenciesIndex]}
          allEntries={formEntries}
          schema={schema}
        />
      )}
      {editIndex !== null && (
        <EntryFormDialog
          onSubmit={(entry) => {
            updateSchema(
              formEntries.map((e, index) => (index === editIndex ? entry : e))
            );
            setEditIndex(null);
          }}
          onClose={() => setEditIndex(null)}
          entry={formEntries[editIndex]}
        />
      )}
      {insertAfterIndex !== null && (
        <EntryFormDialog
          onSubmit={(entry) => {
            updateSchema(
              formEntries
                .slice(0, insertAfterIndex + 1)
                .concat([entry])
                .concat(
                  formEntries.slice(insertAfterIndex + 1, formEntries.length)
                )
            );
            setInsertAfterIndex(null);
          }}
          onClose={() => setInsertAfterIndex(null)}
        />
      )}
    </div>
  );
};
