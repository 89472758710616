import { TextField } from '@innoit/ui-components';
import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { DefinitionsContext } from 'src/components/form-builder/definitions-context';
import { useTranslation } from 'src/i18n';
import { FormBuilderSchema } from 'src/types';

interface JsonEditorProps {
  schema: FormBuilderSchema;
  setSchema: Dispatch<SetStateAction<FormBuilderSchema>>;
  onError: (errors: Array<Error>) => void;
}

export const JsonEditor: React.FC<JsonEditorProps> = ({
  schema,
  setSchema,
  onError,
}) => {
  const { t } = useTranslation();
  const { definitions } = useContext(DefinitionsContext);
  const knownDefinitions = useMemo(
    () => Object.keys(definitions),
    [definitions]
  );

  return (
    <TextField
      multiline
      minRows={10}
      sx={{ textarea: { lineHeight: 1.5, py: 0 } }}
      fullWidth
      value={JSON.stringify(schema, null, 2)}
      onChange={(value) => {
        try {
          const newSchema = JSON.parse(value);

          const missingDefinitions = Object.keys(newSchema.definitions).filter(
            (def) => !knownDefinitions.includes(def)
          );
          if (missingDefinitions.length) {
            onError([
              new Error(t('jsf:misc.addingNewDefinitionsIsNotAllowed')),
              new Error(
                t('jsf:misc.youCanOnlyUseTheExistingDefinitions', {
                  definitions: JSON.stringify(knownDefinitions, null, 2),
                })
              ),
            ]);
          } else {
            setSchema(newSchema);
          }
        } catch (error) {
          onError([error instanceof Error ? error : new Error(`${error}`)]);
        }
      }}
      autoFocus
      required
    />
  );
};
