import {
  Box,
  Button,
  DrawerDialog,
  Select,
  Stack,
  StatusMessage,
} from '@innoit/ui-components';
import { useContext, useMemo, useState } from 'react';
import { getReferenceName } from 'src/components/form-builder/form-builder-form/entry-form/utils';
import { JsonFormContext } from 'src/components/json-forms-base';
import { useTranslation } from 'src/i18n';
import { DependencyTypeEnum, FormBuilderSchema } from 'src/types';
import { FormEntry, FormEntryType } from 'src/types/form-entry';
import { isEnumDefinition } from 'src/types/type-utils';
import { DependencyTypeSelect } from './partials-and-utils/dependency-type-select';

interface DependenciesDialogProps {
  entry: FormEntry;
  onClose: () => void;
  onSubmit: (entry: FormEntry) => void;
  allEntries: Array<FormEntry>;
  schema: FormBuilderSchema;
}

type EditableDependency = Omit<NonNullable<FormEntry['dependency']>, 'name'> & {
  name?: string;
};

const { Requiring } = DependencyTypeEnum;

const hasDependencyTypeMismatch = (
  currentEntry: FormEntry,
  allEntries: Array<FormEntry>
): boolean => {
  const { dependency } = currentEntry;
  const existingDependency = allEntries.find(
    (e) =>
      e.name !== currentEntry.name &&
      e.dependency?.name === currentEntry.dependency?.name
  )?.dependency;

  return !!(
    dependency &&
    existingDependency &&
    dependency.type !== existingDependency.type
  );
};

export const DependenciesDialog: React.FC<DependenciesDialogProps> = ({
  onClose,
  entry,
  allEntries,
  schema,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { translate } = useContext(JsonFormContext);
  const [dependency, setDependency] = useState<EditableDependency | undefined>(
    entry.dependency
  );

  const hasTypeMismatch = hasDependencyTypeMismatch(
    { ...entry, dependency } as FormEntry,
    allEntries
  );

  const dependencyOptions = useMemo(
    () =>
      allEntries
        .filter((e) => e.name !== entry.name)
        .map(({ title, name }) => ({
          value: name,
          optionString: title || name,
        })),
    [allEntries, entry.name]
  );

  const conditionalDependencyOptions = useMemo(() => {
    const dependencyEntry = allEntries.find((e) => e.name === dependency?.name);

    if (!dependency || !dependencyEntry || dependency.type === Requiring) {
      return undefined;
    }

    const reference =
      dependencyEntry.type === FormEntryType.Reference
        ? schema.definitions?.[getReferenceName(dependencyEntry)]
        : undefined;

    const options =
      dependencyEntry.type === FormEntryType.Select
        ? dependencyEntry.enum
        : reference && isEnumDefinition(reference)
        ? reference.enum
        : undefined;

    return options?.map((enumVal) => ({
      value: enumVal,
      optionString: translate(`${enumVal}`, 'schemaForm'),
    }));
  }, [allEntries, dependency, schema.definitions, translate]);

  return (
    <DrawerDialog
      title={t('jsf:form-builder.dependencyDialog.editDependencies')}
      onClose={onClose}
    >
      <Stack gap={4}>
        <DependencyTypeSelect
          value={dependency?.type}
          onChange={(value) => setDependency({ type: value })}
        />
        {dependency && (
          <Stack>
            <Select
              label={t('jsf:form-builder.dependencyDialog.selectEntry')}
              value={dependency.name ?? ''}
              options={dependencyOptions}
              onChange={(value: string) =>
                setDependency({ ...dependency, name: value })
              }
            />
            <small>
              {dependency.type === Requiring
                ? t(
                    'jsf:form-builder.dependencyDialog.dependencyNameRequiringHint'
                  )
                : t(
                    'jsf:form-builder.dependencyDialog.dependencyNameConditionalHint'
                  )}
            </small>
          </Stack>
        )}
        {dependency && conditionalDependencyOptions && (
          <Stack>
            <Select
              label={t('jsf:form-builder.dependencyDialog.selectOptionValue')}
              value={dependency.value ?? ''}
              options={conditionalDependencyOptions}
              onChange={(value: string) =>
                setDependency({ ...dependency, value })
              }
            />
            <small>
              {t('jsf:form-builder.dependencyDialog.dependencyValueHint')}
            </small>
          </Stack>
        )}

        <Box display="flex" justifyContent="space-between">
          <Button
            color="secondary"
            onClick={() => (dependency ? setDependency(undefined) : onClose())}
          >
            {dependency ? t('jsf:action.clearAll') : t('jsf:button.cancel')}
          </Button>
          <Button
            disabled={(dependency && !dependency.name) || hasTypeMismatch}
            onClick={() => onSubmit({ ...entry, dependency } as FormEntry)}
          >
            {t('jsf:button.submit')}
          </Button>
        </Box>
        {hasTypeMismatch && (
          <StatusMessage error>
            {t('jsf:form-builder.dependencyDialog.dependencyTypeMismatch')}
          </StatusMessage>
        )}
      </Stack>
    </DrawerDialog>
  );
};
