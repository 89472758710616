import {
  Box,
  Button,
  Stack,
  StackProps,
  StatusMessage,
} from '@innoit/ui-components';
import { omit } from '@tsp/shared-utils';
import { useCallback, useEffect, useState } from 'react';
import { JsonEditor } from 'src/components/form-builder/json-editor';
import { useTranslation } from 'src/i18n';
import { Definition, FormBuilderSchema } from 'src/types';
import { isObjectSchema } from 'src/types/type-utils';
import { validateSchema } from 'src/util/validate-schema';
import { DefinitionsContextProvider } from './definitions-context';
import { PreviewDialog } from './dialogs/preview-dialog';
import { FormBuilderForm } from './form-builder-form';
import { schemaPropToFormEntry } from './form-builder-form/entry-form/utils';
import { validateEntry } from './form-builder-form/utils/validate-entry';
import { FormBuilderHeader } from './form-builder-header';

export interface FormBuilderProps
  extends Omit<StackProps, 'onChange' | 'onSubmit'> {
  allDefinitions: Record<string, Definition>;
  schema?: FormBuilderSchema;
  onChange?: (newSchema: FormBuilderSchema) => void;
  onSubmit?: (newSchema: FormBuilderSchema) => void;
  onCancel?: () => void;
  withPreview?: boolean;
  typeEditable?: boolean;
  jsonEditor?: boolean;
}

const defaultProps: Required<
  Pick<FormBuilderProps, 'schema' | 'allDefinitions'>
> = {
  schema: {
    type: 'object',
    properties: {},
  },
  allDefinitions: {},
};

export const FormBuilder: React.FC<FormBuilderProps> = ({
  schema: upstreamSchema = defaultProps.schema,
  allDefinitions = upstreamSchema?.definitions ?? defaultProps.allDefinitions,
  withPreview = false,
  typeEditable = false,
  jsonEditor = false,
  onChange,
  onSubmit,
  onCancel,
  ...stackProps
}) => {
  const { t } = useTranslation();
  const [schema, setSchema] = useState(upstreamSchema);
  const [errors, setErrors] = useState(new Set<string>());
  const [showPreview, setShowPreview] = useState(false);

  const isValid = !errors.size;

  const onSchemaUpdate: typeof setSchema = useCallback(
    (valueOrGetter) => {
      setSchema((current) => {
        const value =
          typeof valueOrGetter === 'function'
            ? valueOrGetter(current)
            : valueOrGetter;
        onChange && onChange(value);
        return value;
      });
    },
    [onChange]
  );

  useEffect(() => {
    setSchema(upstreamSchema);
  }, [upstreamSchema]);

  useEffect(() => {
    const validation = validateSchema(omit(schema, 'uiSchema'), {
      strict: false,
    });

    if (!validation.isValid) {
      setErrors(new Set(validation.errors));
    } else if (!isObjectSchema(schema)) {
      const fieldsValid = validateEntry(
        schemaPropToFormEntry(schema as Definition, 'schema')
      );
      !fieldsValid &&
        setErrors(
          (current) => new Set(current.add(t('jsf:misc.someFieldsAreNotValid')))
        );
    }
  }, [schema, t]);

  return (
    <DefinitionsContextProvider definitions={allDefinitions}>
      <Stack gap={3} {...stackProps}>
        <FormBuilderHeader
          schema={schema}
          setSchema={onSchemaUpdate}
          typeEditable={typeEditable}
        />
        {jsonEditor ? (
          <JsonEditor
            schema={schema}
            setSchema={onSchemaUpdate}
            onError={(errs) => setErrors(new Set(errs.map((e) => e.message)))}
          />
        ) : (
          <FormBuilderForm schema={schema} setSchema={onSchemaUpdate} />
        )}
        <Box mt={6} display="flex" justifyContent="space-between">
          {onCancel && (
            <Button onClick={() => onCancel()} color="secondary">
              {t('jsf:button.cancel')}
            </Button>
          )}
          {withPreview ? (
            <Button onClick={() => setShowPreview(true)} disabled={!isValid}>
              {t('jsf:action.preview')}
            </Button>
          ) : (
            onSubmit && (
              <Button onClick={() => onSubmit(schema)} disabled={!isValid}>
                {t('jsf:form-builder.saveForm')}
              </Button>
            )
          )}
        </Box>
        {errors.size ? (
          <div>
            {Array.from(errors).map((error) => (
              <StatusMessage error key={error}>
                {error}
              </StatusMessage>
            ))}
          </div>
        ) : null}
      </Stack>
      {showPreview && (
        <PreviewDialog
          schema={schema}
          onSubmit={() => {
            setShowPreview(false);
            onSubmit && onSubmit(schema);
          }}
          onClose={() => setShowPreview(false)}
        />
      )}
    </DefinitionsContextProvider>
  );
};
