import { Box, Button, Divider, Stack } from '@innoit/ui-components';
import { PropsWithChildren, useState } from 'react';
import { validateEntry } from 'src/components/form-builder/form-builder-form/utils/validate-entry';
import { useTranslation } from 'src/i18n';
import { FormEntry, FormEntryType } from 'src/types/form-entry';
import { EntryPlaceholderField } from './entry-common-fields';
import { TypeSpecificFields } from './type-specific-fields/type-specific-fields';
import { EntryFormHeader } from './_entry-form-header';
import { EntryPreview } from './_entry-preview';

interface EntryFormProps {
  onSubmit: (props: FormEntry) => void;
  onCancel?: () => void;
  entry?: FormEntry;
}

const ButtonArea = ({ children }: PropsWithChildren) => (
  <Box pt={2} display="flex" justifyContent="space-between">
    {children}
  </Box>
);

export const EntryForm: React.FC<EntryFormProps> = ({
  onSubmit,
  onCancel,
  entry: initialProps,
}) => {
  const { t } = useTranslation();
  const isNewEntry = !initialProps;
  const [showPreview, setShowPreview] = useState(false);
  const [entry, setEntry] = useState(
    isNewEntry
      ? ({
          type: FormEntryType.Text,
          title: t('jsf:form-builder.newEntry'),
          name: `new-entry-${Date.now()}`,
        } as FormEntry)
      : initialProps
  );

  const isValid = validateEntry(entry);

  return !showPreview ? (
    <Stack spacing={4}>
      <EntryFormHeader onChange={setEntry} isNewEntry={isNewEntry} {...entry} />
      <TypeSpecificFields entry={entry} setEntry={setEntry} />
      <EntryPlaceholderField entry={entry} setEntry={setEntry} />
      <ButtonArea>
        {onCancel && (
          <Button color="secondary" onClick={onCancel}>
            {t('jsf:button.cancel')}
          </Button>
        )}
        <Button onClick={() => setShowPreview(true)} disabled={!isValid}>
          {t('jsf:action.preview')}
        </Button>
      </ButtonArea>
    </Stack>
  ) : (
    <EntryPreview entry={entry}>
      <Divider />
      <ButtonArea>
        <Button color="secondary" onClick={() => setShowPreview(false)}>
          {t('jsf:button.back')}
        </Button>
        <Button onClick={() => onSubmit(entry)}>
          {t('jsf:action.insert')}
        </Button>
      </ButtonArea>
    </EntryPreview>
  );
};
