import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Icons,
  Stack,
} from '@innoit/ui-components';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { JsonForm } from 'src/components/json-form';
import { useTranslation } from 'src/i18n';
import {
  Definition,
  FormBuilderSchema,
  FormEntry,
  FormEntryType,
} from 'src/types';
import { TypeChangeConfirmationDialog } from './dialogs/type-change-confirmation-dialog';
import {
  getPropertyType,
  schemaPropToFormEntry,
} from './form-builder-form/entry-form/utils';
import { EntryFormHeader } from './form-builder-form/entry-form/_entry-form-header';

interface FormBuilderHeaderProps {
  schema: FormBuilderSchema;
  setSchema: Dispatch<SetStateAction<FormBuilderSchema>>;
  typeEditable?: boolean;
}

const schemaToEntryHeader = (
  schema: FormBuilderSchema
): Pick<FormEntry, 'title' | 'description' | 'type'> => {
  const schemaType = schema.type ?? 'object';
  const { title, type, description } = schemaPropToFormEntry(
    { ...schema, type: schemaType, required: undefined } as Definition,
    'schema'
  );
  return { title, type, description };
};

export const FormBuilderHeader: React.FC<FormBuilderHeaderProps> = ({
  schema,
  setSchema,
  typeEditable,
}) => {
  const { t } = useTranslation();

  const [showEditForm, setsShowEditForm] = useState(false);
  const [entryHeader, setEntryHeader] = useState(schemaToEntryHeader(schema));
  const [conversionConfirmationNeeded, setConversionConfirmationNeeded] =
    useState(false);

  const { title, description, type } = entryHeader;

  const typeChanged = typeEditable && getPropertyType(type) !== schema.type;

  const onSubmit = () => {
    setConversionConfirmationNeeded(false);
    setsShowEditForm(false);
    setSchema(
      typeChanged
        ? { ...entryHeader, type: getPropertyType(type) }
        : { ...schema, title, description }
    );
  };

  return showEditForm ? (
    <Stack gap={4} my={4}>
      {conversionConfirmationNeeded && (
        <TypeChangeConfirmationDialog
          onConfirm={onSubmit}
          onCancel={() => setConversionConfirmationNeeded(false)}
        />
      )}
      <EntryFormHeader
        {...entryHeader}
        onChange={(callback) =>
          setEntryHeader((current) => callback(current as FormEntry))
        }
        typeFieldProps={{
          disabled: !typeEditable,
          typesToExclude: [
            FormEntryType.Null,
            FormEntryType.Integer,
            FormEntryType.Reference,
          ],
        }}
      />
      <ButtonGroup>
        <Button
          sx={{ flexGrow: 1 }}
          color="secondary"
          onClick={() => setsShowEditForm(false)}
        >
          {t('jsf:button.cancel')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          onClick={() =>
            typeChanged ? setConversionConfirmationNeeded(true) : onSubmit()
          }
        >
          {t('jsf:action.done')}
        </Button>
      </ButtonGroup>
    </Stack>
  ) : (
    <Box sx={{ position: 'relative', mt: 4 }} role="button">
      <JsonForm
        schema={{
          title: schema.title ?? t('jsf:form-builder.clickToAddTitle'),
          description:
            schema.description ?? t('jsf:form-builder.clickToSetDescription'),
          type: 'object',
        }}
        readonly
      />
      <IconButton
        sx={{ position: 'absolute', top: 0, right: 0, borderRadius: 0 }}
        icon={Icons.Edit}
        title={t('jsf:form-builder.clickToEdit')}
        onClick={() => setsShowEditForm(true)}
      />
    </Box>
  );
};
