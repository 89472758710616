import Ajv from 'ajv';
import { DateDefinitionFormat, JsonSchema } from 'src/types';

type JsonSchemaDefinition = NonNullable<JsonSchema['definitions']>[number];

const ajvFormats = Object.fromEntries(
  Object.values(DateDefinitionFormat).map((val) => [val, val])
);

/**
 * Use this function to check if a json schema is valid and
 * get a simple (currently not translated) feedback of errors
 * @param schema a json schema object
 * @returns { isValid: true | false, errors?: Array<string> }
 */
export const validateSchema = (
  schema: JsonSchema | JsonSchemaDefinition,
  options?: { strict?: boolean }
): { isValid: boolean; errors?: Array<string> } => {
  const ajv = new Ajv({
    strict: !!options?.strict,
    formats: ajvFormats,
    keywords: ['enumNames'],
    $data: true,
  });

  try {
    ajv.compile(schema);
    return { isValid: true };
  } catch (error) {
    const message = error instanceof Error ? error.message : `${error}`;
    const strictErrors = message.replace(/^strict mode: /, '').split(', ');
    const errors = strictErrors.length ? strictErrors : [message];
    return { isValid: false, errors };
  }
};
