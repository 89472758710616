import { Locale } from '@innoit/localization';
import { FormProps } from '@rjsf/core';
import { MuiForm5 as Form } from '@rjsf/material-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  SubmitArea,
  SubmitAreaProps,
} from 'src/components/json-form/_submit-area';
import { JsonFormContext } from 'src/components/json-forms-base';
import { fallbackLanguage, useTranslation } from 'src/i18n';
import { ExtendedJSONSchema7, JsonSchema } from 'src/types';
import { translateErrors } from 'src/util/translate-errors';
import { translateSchema } from 'src/util/translate-schema';

export interface JsonFormProps<
  J extends JsonSchema,
  T = Record<string, unknown>
> extends Omit<FormProps<T>, 'schema'> {
  schema: J;
  submitAreaProps?: SubmitAreaProps;
  lang?: Locale;
}

const scrollToError = (formId: string) => {
  const element =
    document.querySelector('[role="dialog"] .Mui-error') ||
    document.getElementById(formId);
  element && element.scrollIntoView();
};

export function JsonForm<J extends JsonSchema, T>({
  schema,
  showErrorList = false,
  submitAreaProps,
  lang = fallbackLanguage,
  ...props
}: JsonFormProps<J, T>) {
  const { i18n } = useTranslation();
  const { translate, loadLanguage } = useContext(JsonFormContext);
  const [loadedLang, setLoadedLang] = useState(i18n.language as Locale);
  const translatedSchema = useMemo(
    () =>
      translateSchema(Object.assign({} as JsonSchema, schema), (key) =>
        translate(key, 'schemaForm', loadedLang)
      ),
    [schema, translate, loadedLang]
  );
  const formId = props.id || `json-form-${Date.now()}`;

  const onError = (errors: unknown) => {
    scrollToError(formId);
    return errors;
  };

  if (!props.children) {
    props.children = submitAreaProps ? (
      <SubmitArea {...submitAreaProps} />
    ) : props.readonly ? (
      <SubmitArea hidden />
    ) : undefined;
  }

  useEffect(() => {
    loadLanguage(lang).then(setLoadedLang);
  }, [lang, loadLanguage]);

  return (
    <Form
      key={`${formId}-${loadLanguage}`}
      schema={translatedSchema as ExtendedJSONSchema7}
      id={formId}
      noHtml5Validate
      onError={onError}
      transformErrors={(errors) =>
        translateErrors(errors, (key) => translate(key, 'validationError'))
      }
      showErrorList={showErrorList}
      {...props}
    />
  );
}
