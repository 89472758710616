import { ReactNode, useContext, useMemo } from 'react';
import { DefinitionsContext } from 'src/components/form-builder/definitions-context';
import { JsonForm } from 'src/components/json-form';
import { JsonSchema } from 'src/types';
import { FormEntry } from 'src/types/form-entry';
import { entryToSchemaDefinition } from './utils';

interface EntryPreviewProps {
  entry: FormEntry;
  children?: ReactNode;
  readonly?: boolean;
}

export const EntryPreview: React.FC<EntryPreviewProps> = ({
  entry,
  children = ' ',
  readonly = false,
}) => {
  const { getDefinitionsForSchema } = useContext(DefinitionsContext);
  const { name, definition, required, uiSchema } =
    entryToSchemaDefinition(entry);

  const schema: JsonSchema = useMemo(
    () => ({
      type: 'object',
      properties: { [name]: definition },
      required: required ? [name] : undefined,
    }),
    [definition, name, required]
  );

  const entryDefinitions = useMemo(
    () => getDefinitionsForSchema(schema),
    [getDefinitionsForSchema, schema]
  );

  return (
    <JsonForm
      schema={{ ...schema, definitions: entryDefinitions }}
      uiSchema={uiSchema ? { [name]: uiSchema } : undefined}
      readonly={readonly}
      liveValidate
    >
      {children}
    </JsonForm>
  );
};
