import { JSONSchema7TypeName } from 'json-schema';
import { Dependency } from 'src/types/dependency';
import { JsonSchema } from 'src/types/json-schema';
import { Property, RefProperty } from 'src/types/property';

export type DefinitionType = JSONSchema7TypeName;

export enum DateDefinitionFormat {
  Date = 'date',
  DateTime = 'date-time',
}

export enum OtherStringDefinitionFormat {
  Url = 'url',
  Email = 'email',
  password = 'password',
  IP = 'ip',
}

export enum EnumDefinitionFormat {
  Dropdown = 'dropdown',
  Radio = 'radio',
}

export enum NumberDefinitionFormat {
  Range = 'range',
  Updown = 'updown',
}

export type StringDefinitionFormat =
  | DateDefinitionFormat
  | EnumDefinitionFormat
  | OtherStringDefinitionFormat;

interface BasicDefinition {
  type: DefinitionType | undefined;
  title?: string;
  description?: string;
  nullable?: boolean;
  readOnly?: boolean;
  writeOnly?: boolean;
  $id?: string;
  $comment?: string;
}

export interface StringDefinition extends BasicDefinition {
  type: 'string';
  default?: string;
  format?: StringDefinitionFormat | { $data: string };
  pattern?: string | { $data: string };
  minLength?: number;
  maxLength?: number;
}

export interface EnumDefinition extends Omit<StringDefinition, 'default'> {
  type: 'string';
  enum: Array<string | number>;
  enumNames?: Array<string>;
  default?: EnumDefinition['enum'][number];
}

export interface BooleanDefinition extends BasicDefinition {
  type: 'boolean';
  default?: boolean;
}

export interface NumberDefinition extends BasicDefinition {
  type: 'number';
  default?: number;
  minimum?: number | { $data: string };
  maximum?: number | { $data: string };
  exclusiveMinimum?: number | { $data: string };
  exclusiveMaximum?: number | { $data: string };
  multipleOf?: number | { $data: string };
  format?: NumberDefinitionFormat;
}

export interface IntegerDefinition extends Omit<NumberDefinition, 'type'> {
  type: 'integer';
}

export interface ArrayDefinition extends BasicDefinition {
  type: 'array';
  items: RefProperty & Pick<JsonSchema, 'minItems' | 'maxItems'>;
  minItems?: number | { $data: string };
  maxItems?: number | { $data: string };
  uniqueItems?: boolean;
}

export interface ObjectDefinition<T extends string = string>
  extends BasicDefinition {
  type: 'object';
  properties: Record<T, Property>;
  required?: Array<T>;
  dependencies?: Record<T, Dependency<T>>; // Deprecated! Read more: https://react-jsonschema-form.readthedocs.io/en/latest/usage/dependencies/
  dependentRequired?: Record<T, Array<T>>;
  minProperties?: number;
  maxProperties?: number;
  additionalProperties?: boolean;
}

export interface NullDefinition extends BasicDefinition {
  type: 'null';
}

export interface RefDefinition extends BasicDefinition {
  $ref: string;
  type: undefined;
}

export type Definition =
  | StringDefinition
  | NumberDefinition
  | IntegerDefinition
  | BooleanDefinition
  | EnumDefinition
  | ArrayDefinition
  | ObjectDefinition
  | NullDefinition
  | RefDefinition;
