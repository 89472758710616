import { merge, omit, pick } from '@tsp/shared-utils';
import {
  ConditionalDependency,
  ConditionalEnumDependency,
  Definition,
  DefinitionType,
  Dependency,
  DependencyTypeEnum,
  FormBuilderSchema,
  RefDefinition,
  UiSchema,
} from 'src/types';
import {
  FormEntry,
  FormEntryType,
  FormMultiSelectEntry,
} from 'src/types/form-entry';
import {
  isArrayDefinition,
  isBooleanDefinition,
  isConditionalDependency,
  isConditionalEnumDependency,
  isDateDefinition,
  isEnumDefinition,
  isNumberDefinition,
  isObjectDefinition,
  isRefDefinition,
  isRequiringDependency,
  isStringDefinition,
} from 'src/types/type-utils';

const formEntryTypeToPropertyType: Record<
  FormEntryType,
  DefinitionType | undefined
> = {
  [FormEntryType.Array]: 'array',
  [FormEntryType.MultiSelect]: 'array',
  [FormEntryType.Checkbox]: 'boolean',
  [FormEntryType.Date]: 'string',
  [FormEntryType.Form]: 'object',
  [FormEntryType.Integer]: 'integer',
  [FormEntryType.Number]: 'number',
  [FormEntryType.Null]: 'null',
  [FormEntryType.Reference]: undefined,
  [FormEntryType.Select]: 'string',
  [FormEntryType.Text]: 'string',
};

export const getPropertyType = (type: FormEntryType) =>
  formEntryTypeToPropertyType[type];

export const getFormEntryType = (definition: Definition) =>
  isBooleanDefinition(definition)
    ? FormEntryType.Checkbox
    : isNumberDefinition(definition)
    ? FormEntryType.Number
    : isRefDefinition(definition)
    ? FormEntryType.Reference
    : isArrayDefinition(definition) && definition.uniqueItems
    ? FormEntryType.MultiSelect
    : isArrayDefinition(definition)
    ? FormEntryType.Array
    : isDateDefinition(definition)
    ? FormEntryType.Date
    : isEnumDefinition(definition)
    ? FormEntryType.Select
    : isStringDefinition(definition)
    ? FormEntryType.Text
    : isObjectDefinition(definition)
    ? FormEntryType.Form
    : undefined;

export const schemaPropToFormEntry = (
  property: Definition,
  name: string,
  uiSchema?: UiSchema,
  required?: boolean,
  dependency?: FormEntry['dependency']
): FormEntry =>
  ({
    ...property,
    type: getFormEntryType(property),
    name,
    uiSchema: uiSchema?.[name],
    required,
    dependency,
  } as FormEntry);

export const castToSchemaProperties = (
  dynamicDepProperties: ConditionalEnumDependency['oneOf'][number]['properties']
): NonNullable<FormBuilderSchema['properties']> =>
  Object.fromEntries(
    Object.entries(dynamicDepProperties).map(([name, entry]) => [
      name,
      ('enum' in entry ? { type: 'string', ...entry } : entry) as Definition,
    ])
  );

export const getDependencyProperties = (
  dependencyName: string,
  dependency: ConditionalDependency | ConditionalEnumDependency
): FormBuilderSchema['properties'] =>
  isConditionalDependency(dependency)
    ? dependency.properties
    : isConditionalEnumDependency(dependency)
    ? merge(
        ...dependency.oneOf.map(({ properties: conditionalProperties }) =>
          omit(castToSchemaProperties(conditionalProperties), dependencyName)
        )
      )
    : undefined;

export const getDependencyValue = (
  dependency: Dependency,
  dependencyName: string,
  entryName: string
): string | undefined => {
  if (isConditionalEnumDependency(dependency)) {
    const def = dependency.oneOf.find(
      ({ properties }) => entryName in properties
    );
    const condition = def?.properties?.[dependencyName];

    return condition && 'enum' in condition
      ? condition.enum[0].toString()
      : undefined;
  }
  return undefined;
};

export const schemaDependencyToEntryDependency = (
  dependency: Dependency,
  dependencyName: string,
  entryName: string
): NonNullable<FormEntry['dependency']> => ({
  type: isRequiringDependency(dependency)
    ? DependencyTypeEnum.Requiring
    : DependencyTypeEnum.Conditional,
  name: dependencyName,
  value: getDependencyValue(dependency, dependencyName, entryName),
});

export const schemaToFormEntries = (
  schema: FormBuilderSchema,
  dependencyName?: string
): Array<FormEntry> => {
  const dependantEntries = dependencyName
    ? []
    : Object.entries(
        schema.dependencies ?? ({} as Record<string, Dependency>)
      ).flatMap(([name, dependency]) => {
        const properties = isRequiringDependency(dependency)
          ? pick(schema.properties ?? {}, ...dependency)
          : getDependencyProperties(name, dependency);
        return schemaToFormEntries({ ...schema, properties }, name);
      });

  const dependantEntriesNames = dependantEntries.map((e) => e.name);
  const missingProps = omit(schema.properties ?? {}, ...dependantEntriesNames);
  const dependency = dependencyName
    ? schema.dependencies?.[dependencyName]
    : undefined;

  const propEntries = Object.entries(missingProps).map(([name, prop]) =>
    schemaPropToFormEntry(
      prop as Definition,
      name,
      schema.uiSchema,
      schema.required?.includes(name),
      dependency && dependencyName
        ? schemaDependencyToEntryDependency(dependency, dependencyName, name)
        : undefined
    )
  );

  // the entries from the dependencies potentially contain
  // more metadata, therefore they should override the prop entries
  return [...propEntries, ...dependantEntries];
};

export const entryToSchemaDefinition = ({
  name,
  type,
  required,
  uiSchema,
  dependency,
  ...entryProps
}: FormEntry) => ({
  name: name ?? '',
  definition: Object.assign({} as Definition, {
    ...entryProps,
    ...(type === FormEntryType.Reference
      ? {}
      : { type: getPropertyType(type) }),
  }),
  uiSchema,
  required,
  dependency,
});

export const getReferenceName = (
  reference: RefDefinition | NonNullable<FormMultiSelectEntry['items']>
) => (reference.$ref ?? '').replace('#/definitions/', '');

export const getReferenceRef = (referenceName: string) =>
  `#/definitions/${referenceName}`;

export const getConditionalEnumDependencyConditionByPropName = (
  dependency: ConditionalEnumDependency,
  propName: string
): ConditionalEnumDependency['oneOf'][number] | undefined =>
  dependency.oneOf.find(({ properties }) => propName in properties);

export const jsonCopy = <T extends object>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));
