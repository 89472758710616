import { Box, Button, Dialog, Grid } from '@innoit/ui-components';
import { useTranslation } from 'src/i18n';

interface EntryTypeHelpDialogProps {
  onClose: () => void;
}

export const EntryTypeHelpDialog: React.FC<EntryTypeHelpDialogProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t('jsf:misc.learnMore')} onClose={onClose} size={'md'}>
      <Box alignItems="center" textAlign="center" mt={3}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: 3,
            mb: 3,
          }}
        >
          <strong>{t('jsf:enums.FormEntryType.Array')}</strong>
          {t('jsf:learnMore.ArrayInfo')}
          <strong>{t('jsf:enums.FormEntryType.Checkbox')}</strong>
          {t('jsf:learnMore.CheckboxInfo')}
          <strong>{t('jsf:enums.FormEntryType.Date')}</strong>
          {t('jsf:learnMore.DateInfo')}
          <strong>{t('jsf:enums.FormEntryType.Number')}</strong>
          {t('jsf:learnMore.NumberInfo')}
          <strong>{t('jsf:enums.FormEntryType.Reference')}</strong>
          {t('jsf:learnMore.ReferenceInfo')}
          <strong>{t('jsf:enums.FormEntryType.Select')}</strong>
          {t('jsf:learnMore.SelectInfo')}
          <strong>{t('jsf:enums.FormEntryType.MultiSelect')}</strong>
          {t('jsf:learnMore.MultiSelectInfo')}
          <strong>{t('jsf:enums.FormEntryType.Text')}</strong>
          {t('jsf:learnMore.TextInfo')}
        </Box>
        <Grid container direction="column" gap={5}>
          <Grid item>
            <Button onClick={onClose} color="secondary">
              {t('jsf:action.close')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
