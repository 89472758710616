import {
  ApiError,
  ApiStatus,
  FormComponentDTO,
  FormComponentStatusEnum,
  FormPurposeEnum,
  useGetFormComponentsWithStatusAndPurpose,
  useUpdateFormComponentStatus,
} from '@tsp/api-hooks';
import { contextNotInitialized } from '@tsp/shared-utils';
import { createContext, ReactNode, useEffect, useState } from 'react';

export interface IFormComponentContext {
  fetchedComponents: Record<
    FormComponentStatusEnum,
    Readonly<Array<FormComponentDTO>>
  >;
  fetchedForms: Record<
    FormComponentStatusEnum,
    Readonly<Array<FormComponentDTO>>
  >;
  fetchError?: ReturnType<
    typeof useGetFormComponentsWithStatusAndPurpose
  >['error'];
  fetchStatus: ReturnType<
    typeof useGetFormComponentsWithStatusAndPurpose
  >['status'];
  refetch: (args: {
    statuses: Array<FormComponentStatusEnum>;
    purpose: FormPurposeEnum;
  }) => void;
  fetchVersion?: string;
  updateFormComponentStatus: (
    formComponent: FormComponentDTO,
    targetStatus: FormComponentStatusEnum
  ) => void;
  updateStatusResult?: ReturnType<
    typeof useUpdateFormComponentStatus
  >['result'];
}

const { FinalForm, ReusableComponent } = FormPurposeEnum;
const { Active, Draft, Archived } = FormComponentStatusEnum;

export const FormComponentContext = createContext<IFormComponentContext>({
  fetchedComponents: {
    [Active]: [],
    [Archived]: [],
    [Draft]: [],
  },
  fetchedForms: {
    [Active]: [],
    [Archived]: [],
    [Draft]: [],
  },
  refetch: contextNotInitialized,
  updateFormComponentStatus: contextNotInitialized,
  fetchStatus: 'idle',
});

export const FormComponentContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [fetchStatus, setFetchStatus] = useState<ApiStatus>('idle');
  const [fetchError, setFetchError] = useState<ApiError>();

  const [onStatusUpdate, setOnStatusUpdate] = useState(() => () => {
    null;
  });

  const {
    dispatch: updateStatus,
    result: updateStatusResult,
    reset: resetUpdateStatusResult,
  } = useUpdateFormComponentStatus();

  const updateFormComponentStatus: IFormComponentContext['updateFormComponentStatus'] =
    (formComponent, targetStatus) => {
      const { id, status, purpose } = formComponent;
      setOnStatusUpdate(() => () => {
        refetch({ statuses: [status, targetStatus], purpose });
        resetUpdateStatusResult();
      });
      updateStatus({
        formComponentId: id,
        status: targetStatus,
      });
    };

  const activeFinalFormResult = useGetFormComponentsWithStatusAndPurpose({
    purpose: FinalForm,
    status: [Active],
  });

  const draftFinalFormResult = useGetFormComponentsWithStatusAndPurpose({
    purpose: FinalForm,
    status: [Draft],
  });

  const archivedFinalFormResult = useGetFormComponentsWithStatusAndPurpose({
    purpose: FinalForm,
    status: [Archived],
  });

  const activeReusableComponentResult =
    useGetFormComponentsWithStatusAndPurpose({
      purpose: ReusableComponent,
      status: [Active],
    });

  const draftReusableComponentResult = useGetFormComponentsWithStatusAndPurpose(
    {
      purpose: ReusableComponent,
      status: [Draft],
    }
  );

  const archivedReusableComponentResult =
    useGetFormComponentsWithStatusAndPurpose({
      purpose: ReusableComponent,
      status: [Archived],
    });

  const refetch: IFormComponentContext['refetch'] = ({ statuses, purpose }) => {
    if (purpose === FinalForm) {
      statuses.includes(Active) && activeFinalFormResult.refetch();
      statuses.includes(Draft) && draftFinalFormResult.refetch();
      statuses.includes(Archived) && archivedFinalFormResult.refetch();
    } else {
      statuses.includes(Active) && activeReusableComponentResult.refetch();
      statuses.includes(Draft) && draftReusableComponentResult.refetch();
      statuses.includes(Archived) && archivedReusableComponentResult.refetch();
    }
  };

  useEffect(() => {
    const fetchStatuses = [
      activeReusableComponentResult.status,
      draftReusableComponentResult.status,
      archivedReusableComponentResult.status,
      activeFinalFormResult.status,
      draftFinalFormResult.status,
      archivedFinalFormResult.status,
    ];
    setFetchStatus(
      fetchStatuses.includes('error')
        ? 'error'
        : fetchStatuses.every((s) => s === 'idle')
        ? 'idle'
        : fetchStatuses.every((s) => s === 'success')
        ? 'success'
        : 'pending'
    );
  }, [
    activeFinalFormResult.status,
    archivedFinalFormResult.status,
    draftFinalFormResult.status,
    activeReusableComponentResult.status,
    archivedReusableComponentResult.status,
    draftReusableComponentResult.status,
  ]);

  useEffect(() => {
    setFetchError(
      activeFinalFormResult.error ??
        draftFinalFormResult.error ??
        archivedFinalFormResult.error
    );
  }, [
    activeFinalFormResult.error,
    draftFinalFormResult.error,
    archivedFinalFormResult.error,
  ]);

  useEffect(() => {
    setFetchError(
      activeReusableComponentResult.error ??
        draftReusableComponentResult.error ??
        archivedReusableComponentResult.error
    );
  }, [
    activeReusableComponentResult.error,
    draftReusableComponentResult.error,
    archivedReusableComponentResult.error,
  ]);

  useEffect(() => {
    updateStatusResult.status === 'success' && onStatusUpdate();
  }, [onStatusUpdate, updateStatusResult.status]);

  return (
    <FormComponentContext.Provider
      value={{
        fetchStatus,
        fetchError,
        refetch,
        fetchedComponents: {
          [Active]: activeReusableComponentResult.formComponents ?? [],
          [Draft]: draftReusableComponentResult.formComponents ?? [],
          [Archived]: archivedReusableComponentResult.formComponents ?? [],
        },
        fetchedForms: {
          [Active]: activeFinalFormResult.formComponents ?? [],
          [Draft]: draftFinalFormResult.formComponents ?? [],
          [Archived]: archivedFinalFormResult.formComponents ?? [],
        },
        updateFormComponentStatus,
        updateStatusResult,
      }}
    >
      {children}
    </FormComponentContext.Provider>
  );
};
